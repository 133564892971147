// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { readRecord } from '../../Services/backend/apiCalls';
// import arrow from '../../assets/Images/RightArrow.svg';

// interface Internship {
//     application_deadline: string | number | Date;
//     id: number;
//     title: string;
//     company: string;
//     createdAt: string;
//     description: string;
//     thumbnail?: string;
//     internship_type?: string;
//     user_idSt: string[] | null;
//     start_date: string;
// }

// const lineClampStyle = `
//   .line-clamp-2 {
//       display: -webkit-box;
//       -webkit-line-clamp: 2;
//       -webkit-box-orient: vertical;
//       overflow: hidden;
//   }
// `;

// export const AppliedStudentInternship = () => {
//     const [internships, setInternships] = useState<Internship[]>([]);
//     const [userId, setUserId] = useState<string | null>(null);
//     const [loading, setLoading] = useState<boolean>(true);
//     const navigate = useNavigate();

//     useEffect(() => {
//         const storedUserId = localStorage.getItem('uid');
//         if (storedUserId) {
//             setUserId(storedUserId);
//         }
//     }, []);

//     useEffect(() => {
//         const fetchInternships = async () => {
//             setLoading(true);
//             try {
//                 const data = await readRecord({}, 'internships');
//                 const currentDate = new Date();

//                 // Filter internships where the current user has applied
//                 const appliedInternships = data.filter(
//                     (internship: Internship) => {
//                         return (
//                             Array.isArray(internship.user_idSt) &&
//                             userId &&
//                             internship.user_idSt.includes(userId)
//                         );
//                     }
//                 );

//                 setInternships(appliedInternships);
//             } catch (error) {
//                 console.error('Error fetching internships:', error);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         if (userId) {
//             fetchInternships();
//         }
//     }, [userId]);

//     if (loading) {
//         return (
//             <div className="w-11/12 mx-auto py-6 min-h-screen flex flex-col items-center justify-center">
//                 <div className="w-16 h-16 border-4 border-t-blue-500 border-b-blue-500 rounded-full animate-spin"></div>
//                 <p className="mt-4 text-lg text-gray-600">
//                     Loading internships...
//                 </p>
//             </div>
//         );
//     }

//     return (
//         <>
//             <style>{lineClampStyle}</style>
//             <div className="w-11/12 mx-auto py-6">
//                 <div className="mt-6">
//                     {internships.length === 0 ? (
//                         <div className="text-center py-8">
//                             <p className="text-xl text-gray-600">
//                                 No applied internships found
//                             </p>
//                         </div>
//                     ) : (
//                         internships.map((internship) => (
//                             <div>
//                                 <div
//                                     key={internship.id}
//                                     className="bg-white p-6 rounded-lg shadow-lg mb-6 flex flex-col sm:flex-row justify-between items-center"
//                                 >
//                                     <div className="flex flex-col sm:flex-row items-center sm:items-start sm:space-x-6 w-full h-full">
//                                         <img
//                                             src={
//                                                 internship.thumbnail ||
//                                                 'https://via.placeholder.com/150?text=No+Image'
//                                             }
//                                             alt={internship.title}
//                                             className="w-full sm:w-24 h-40 sm:h-24 object-cover rounded-lg mb-4 sm:mb-0"
//                                         />
//                                         <div className="text-center sm:text-left w-full">
//                                             <h2 className="text-lg sm:text-[24px] font-[500] truncate">
//                                                 {internship.title}
//                                             </h2>
//                                             <p className="text-gray-600 text-sm sm:text-base truncate">
//                                                 {internship.company ||
//                                                     'No company info'}
//                                             </p>
//                                             <p className="text-gray-600 text-sm sm:text-base truncate">
//                                                 {internship.internship_type ===
//                                                 'Stipend'
//                                                     ? 'Paid (With Stipend)'
//                                                     : internship.internship_type ===
//                                                       'Paid'
//                                                     ? 'Paid (By Student)'
//                                                     : internship.internship_type ===
//                                                       'Free'
//                                                     ? 'Free'
//                                                     : 'No internship type'}
//                                             </p>
// <p className="text-gray-500">
//     Started Date on:{' '}
//     {(() => {
//         const date =
//             internship.start_date;
//         if (!date)
//             return 'No date provided';

//         const parsedDate = new Date(
//             date
//         );
//         return parsedDate.getTime() >
//             0
//             ? parsedDate.toDateString()
//             : 'No date provided';
//     })()}
// </p>
//                                             <p className="text-gray-600 text-sm sm:text-base line-clamp-2">
//                                                 {internship.description ||
//                                                     'No description available'}
//                                             </p>
//                                         </div>
//                                     </div>

//                                     <button
//                                         className="mt-4 sm:mt-0 py-2 px-4 text-white font-semibold rounded-md w-full sm:w-auto bg-[#065FEF] flex items-center justify-center"
//                                         onClick={() =>
//                                             navigate(
//                                                 `/internship-info/${internship.id}`
//                                             )
//                                         }
//                                     >
//                                         Details
//                                     </button>
//                                 </div>
//                             </div>
//                         ))
//                     )}
//                 </div>
//             </div>
//         </>
//     );
// };

// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { readRecord } from '../../Services/backend/apiCalls';

// interface Internship {
//     duration_unit: string;
//     application_deadline: string | number | Date;
//     id: number;
//     title: string;
//     company: string;
//     createdAt: string;
//     description: string;
//     thumbnail?: string;
//     internship_type?: string;
//     user_idSt: string[] | null;
//     start_date: string;
//     duration?: string;
//     mode?: string;
//     stipend?: string;
//     learners?: number;
// }

// const lineClampStyle = `
//   .line-clamp-3 {
//       display: -webkit-box;
//       -webkit-line-clamp: 3;
//       -webkit-box-orient: vertical;
//       overflow: hidden;
//   }
// `;

// const formatDate = (date: string) => {
//     const d = new Date(date);
//     return d
//         .toLocaleDateString('en-GB', {
//             day: '2-digit',
//             month: 'short',
//             year: 'numeric',
//         })
//         .replace(/ /g, '-');
// };

// export const AppliedStudentInternship = () => {
//     const [internships, setInternships] = useState<Internship[]>([]);
//     const [userId, setUserId] = useState<string | null>(null);
//     const [loading, setLoading] = useState<boolean>(true);
//     const navigate = useNavigate();

//     useEffect(() => {
//         const storedUserId = localStorage.getItem('uid');
//         if (storedUserId) {
//             setUserId(storedUserId);
//         }
//     }, []);

//     useEffect(() => {
//         const fetchInternships = async () => {
//             setLoading(true);
//             try {
//                 const data = await readRecord({}, 'internships');
//                 console.log(data);

//                 const appliedInternships = data.filter(
//                     (internship: Internship) => {
//                         return (
//                             Array.isArray(internship.user_idSt) &&
//                             userId &&
//                             internship.user_idSt.includes(userId)
//                         );
//                     }
//                 );
//                 setInternships(appliedInternships);
//             } catch (error) {
//                 console.error('Error fetching internships:', error);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         if (userId) {
//             fetchInternships();
//         }
//     }, [userId]);

//     if (loading) {
//         return (
//             <div className="w-11/12 mx-auto py-6 min-h-screen flex flex-col items-center justify-center">
//                 <div className="w-16 h-16 border-4 border-t-blue-500 border-b-blue-500 rounded-full animate-spin"></div>
//                 <p className="mt-4 text-lg text-gray-600">
//                     Loading internships...
//                 </p>
//             </div>
//         );
//     }

//     return (
//         <>
//             <style>{lineClampStyle}</style>
//             <div className="w-11/12 mx-auto py-6  ">
//                 <div className="mt-6">
//                     {internships.length === 0 ? (
//                         <div className="text-center py-8">
//                             <p className="text-xl text-gray-600">
//                                 No applied internships found
//                             </p>
//                         </div>
//                     ) : (
//                         internships.map((internship) => (
//                             <div
//                                 key={internship.id}
//                                 className="bg-white p-6 rounded-lg  mb-6"
//                             >
//                                 <div className="flex flex-col space-y-4">
//                                     {/* Header Section */}
//                                     {/* <div className="sm:flex-row flex-col justify-between items-start w-full"> */}
//                                     <div className="flex flex-col sm:flex-row justify-between items-start w-full">
//                                         <div className="flex items-center gap-4">
//                                             <h2 className="text-[32px] font-medium text-blue-600   w-28 sm:w-auto  truncate">
//                                                 {internship.title}
//                                             </h2>
//                                             <span className="text-gray-500 text-sm">
//                                                 {formatDate(
//                                                     internship.createdAt
//                                                 )}
//                                             </span>
//                                         </div>
//                                         <button
//                                             className="py-2 px-6 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
//                                             onClick={() =>
//                                                 navigate(
//                                                     `/internship-info/${internship.id}`
//                                                 )
//                                             }
//                                         >
//                                             Details
//                                         </button>
//                                     </div>

//                                     {/* <div className="bg-green-100 px-3 py-1 rounded-full text-sm text-green-800 w-fit">
//                                         {internship.internship_type ||
//                                             'Institute'}
//                                     </div> */}
//                                     <div className="bg-green-100 px-3 py-1 rounded-full text-sm text-[#07bea1] w-fit">
//                                         Institute
//                                     </div>

//                                     {/* Description */}
//                                     <p className="text-gray-700 line-clamp-3">
//                                         {internship.description ||
//                                             'No description available'}
//                                     </p>

//                                     {/* Left Column */}
//                                     <div className="grid grid-cols-3">
//                                         <div className="space-y-3">
//                                             {/* <div className="flex items-center space-x-2">
//                                                 <span className="text-blue-600">
//                                                     <svg
//                                                         className="w-5 h-5"
//                                                         fill="none"
//                                                         stroke="currentColor"
//                                                         viewBox="0 0 24 24"
//                                                     >
//                                                         <path
//                                                             strokeLinecap="round"
//                                                             strokeLinejoin="round"
//                                                             strokeWidth="2"
//                                                             d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
//                                                         />
//                                                     </svg>
//                                                 </span>
//                                                 <span>
//                                                     {internship.learners || 1}{' '}
//                                                     Learner
//                                                 </span>
//                                             </div> */}

//                                             <div className="flex items-center space-x-2">
//                                                 <span className="h-6 w-[1px]  bg-gray-300"></span>
//                                                 <span className="text-blue-600">
//                                                     <svg
//                                                         className="w-5 h-5"
//                                                         fill="none"
//                                                         stroke="currentColor"
//                                                         viewBox="0 0 24 24"
//                                                     >
//                                                         <path
//                                                             strokeLinecap="round"
//                                                             strokeLinejoin="round"
//                                                             strokeWidth="2"
//                                                             d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
//                                                         />
//                                                     </svg>
//                                                 </span>
//                                                 <span>{internship.mode}</span>
//                                             </div>

//                                             <div className="flex items-center space-x-2">
//                                                 <span className="h-6 w-[1px]  bg-gray-300"></span>

//                                                 <span className="text-blue-600">
//                                                     <svg
//                                                         className="w-5 h-5"
//                                                         fill="none"
//                                                         stroke="currentColor"
//                                                         viewBox="0 0 24 24"
//                                                     >
//                                                         <path
//                                                             strokeLinecap="round"
//                                                             strokeLinejoin="round"
//                                                             strokeWidth="2"
//                                                             d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
//                                                         />
//                                                     </svg>
//                                                 </span>
//                                                 <p className="text">
//                                                     Started Date on:{' '}
//                                                     {(() => {
//                                                         const date =
//                                                             internship.start_date;
//                                                         if (!date)
//                                                             return 'No date provided';

//                                                         const parsedDate =
//                                                             new Date(date);
//                                                         return parsedDate.getTime() >
//                                                             0
//                                                             ? parsedDate.toDateString()
//                                                             : 'No date provided';
//                                                     })()}
//                                                 </p>
//                                             </div>
//                                         </div>

//                                         {/* Right Column */}
//                                         <div className="space-y-3">
//                                             <span className="h-6 w-[1px]  bg-gray-300"></span>

//                                             <div className="flex items-center space-x-2">
//                                                 <span className="h-6 w-[1px]  bg-gray-300"></span>

//                                                 <span className="text-blue-600">
//                                                     <svg
//                                                         className="w-5 h-5"
//                                                         fill="none"
//                                                         stroke="currentColor"
//                                                         viewBox="0 0 24 24"
//                                                     >
//                                                         <path
//                                                             strokeLinecap="round"
//                                                             strokeLinejoin="round"
//                                                             strokeWidth="2"
//                                                             d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
//                                                         />
//                                                     </svg>
//                                                 </span>
//                                                 {/* <span>
//                                                     {internship.duration}{' '}
//                                                     {internship.duration_unit}
//                                                 </span> */}
//                                                 <span>
//                                                     {internship.duration}{' '}
//                                                     {internship.duration_unit.replace(
//                                                         /s$/,
//                                                         ''
//                                                     )}
//                                                 </span>
//                                             </div>

//                                             {/* <div className="flex items-center space-x-2">
//                                                 <span className="text-blue-600">
//                                                     ₹
//                                                 </span>
//                                                 <span>
//                                                     {internship.stipend}
//                                                 </span>
//                                             </div> */}
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         ))
//                     )}
//                 </div>
//             </div>
//         </>
//     );
// };

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { readRecord } from '../../Services/backend/apiCalls';

interface Internship {
    duration_unit: string;
    application_deadline: string | number | Date;
    id: number;
    title: string;
    company: string;
    createdAt: string;
    description: string;
    thumbnail?: string;
    internship_type?: string;
    user_idSt: string[] | null;
    start_date: string;
    duration?: string;
    mode?: string;
    stipend?: string;
    learners?: number;
}

const lineClampStyle = `
  .line-clamp-3 {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
  }
`;

const formatDate = (date: string) => {
    const d = new Date(date);
    return d
        .toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        })
        .replace(/ /g, '-');
};

export const AppliedStudentInternship = () => {
    const [internships, setInternships] = useState<Internship[]>([]);
    const [userId, setUserId] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    useEffect(() => {
        const storedUserId = localStorage.getItem('uid');
        if (storedUserId) {
            setUserId(storedUserId);
        }
    }, []);

    useEffect(() => {
        const fetchInternships = async () => {
            setLoading(true);
            try {
                const data = await readRecord({}, 'internships');
                const appliedInternships = data.filter(
                    (internship: Internship) => {
                        return (
                            Array.isArray(internship.user_idSt) &&
                            userId &&
                            internship.user_idSt.includes(userId)
                        );
                    }
                );
                setInternships(appliedInternships);
            } catch (error) {
                console.error('Error fetching internships:', error);
            } finally {
                setLoading(false);
            }
        };

        if (userId) {
            fetchInternships();
        }
    }, [userId]);

    if (loading) {
        return (
            <div className="w-11/12 mx-auto py-6 min-h-screen flex flex-col items-center justify-center">
                <div className="w-16 h-16 border-4 border-t-blue-500 border-b-blue-500 rounded-full animate-spin"></div>
                <p className="mt-4 text-lg text-gray-600">
                    Loading internships...
                </p>
            </div>
        );
    }

    return (
        <>
            <style>{lineClampStyle}</style>
            <div className="w-11/12 mx-auto py-6">
                <div className="mt-6">
                    {internships.length === 0 ? (
                        <div className="text-center py-8">
                            <p className="text-xl text-gray-600">
                                No applied internships found
                            </p>
                        </div>
                    ) : (
                        internships.map((internship) => (
                            <div
                                key={internship.id}
                                className="bg-white p-4 sm:p-6 rounded-lg mb-6 relative"
                            >
                                <div className="flex flex-col space-y-4">
                                    <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2 sm:gap-4 w-full">
                                        <h2 className="text-2xl sm:text-[32px] font-medium text-blue-600 truncate max-w-[200px] sm:max-w-none">
                                            {internship.title}
                                        </h2>
                                        <span className="text-gray-500 text-sm">
                                            {formatDate(internship.createdAt)}
                                        </span>
                                    </div>

                                    {/* Desktop button */}
                                    <div className="hidden sm:block absolute top-6 right-6">
                                        <button
                                            className="py-2 px-6 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
                                            onClick={() =>
                                                navigate(
                                                    `/internship-info/${internship.id}`
                                                )
                                            }
                                        >
                                            Details
                                        </button>
                                    </div>

                                    <div className="bg-green-100 px-3 py-1 rounded-full text-sm text-[#07bea1] w-fit">
                                        Institute
                                    </div>

                                    <p className="text-gray-700 line-clamp-3">
                                        {internship.description ||
                                            'No description available'}
                                    </p>

                                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                                        <div className="space-y-3">
                                            <div className="flex items-center space-x-2">
                                                <span className="h-6 w-[1px] bg-gray-300 hidden sm:block"></span>
                                                <span className="text-blue-600">
                                                    <svg
                                                        className="w-5 h-5"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                                                        />
                                                    </svg>
                                                </span>
                                                <span>{internship.mode}</span>
                                            </div>

                                            <div className="flex items-center space-x-2">
                                                <span className="h-6 w-[1px] bg-gray-300 hidden sm:block"></span>
                                                <span className="text-blue-600">
                                                    <svg
                                                        className="w-5 h-5"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                                        />
                                                    </svg>
                                                </span>
                                                <p className="text-sm sm:text-base">
                                                    Started Date on:{' '}
                                                    {(() => {
                                                        const date =
                                                            internship.start_date;
                                                        if (!date)
                                                            return 'No date provided';
                                                        const parsedDate =
                                                            new Date(date);
                                                        return parsedDate.getTime() >
                                                            0
                                                            ? parsedDate.toDateString()
                                                            : 'No date provided';
                                                    })()}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="space-y-3">
                                            <div className="flex items-center space-x-2">
                                                <span className="h-6 w-[1px] bg-gray-300 hidden sm:block"></span>
                                                <span className="text-blue-600">
                                                    <svg
                                                        className="w-5 h-5"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                                                        />
                                                    </svg>
                                                </span>
                                                <span>
                                                    {internship.duration}{' '}
                                                    {internship.duration_unit.replace(
                                                        /s$/,
                                                        ''
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Mobile button */}
                                    <div className="mt-4 sm:hidden">
                                        <button
                                            className="w-full py-2 px-6 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
                                            onClick={() =>
                                                navigate(
                                                    `/internship-info/${internship.id}`
                                                )
                                            }
                                        >
                                            Details
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </>
    );
};

export default AppliedStudentInternship;

import React, { useEffect, useRef, useState } from 'react';
import { readRecord } from '../../Services/backend/apiCalls';
import { useAppSelector } from '../..';
import { Card } from './Card';
import ScrollY from '../../assets/Images/ScrollY.svg';
import { DashBoardLoader } from './NoDataChecker';
import circleGreen from '../../assets/Images/circleGreen.svg';
import circleBlue from '../../assets/Images/circleBlue.svg';
import './card.css';
import { useNavigate } from 'react-router-dom';

export const Bidding = ({ biddings, dashLoading }: any) => {
    const scrollRef: any = useRef();
    const navigate = useNavigate();
    const [biddingQuery, setBiddingQuery] = useState('accepted');
    const [biddingsData, setBiddingsData] = useState(biddings);
    const [loading, setLoading] = useState(dashLoading);
    const [page, setpage] = useState(0);
    const { signin }: any = useAppSelector((state) => state);
    const role = signin.signinValue.role;
    const userType = ['TI', 'FL'].includes(role) ? 'trainee' : 'trainer';
    const isTrainer = ['TI', 'FL'].includes(role);
    const { appConfig } = useAppSelector(
        ({ appConfig }: any) => appConfig['configData']
    );

    const fetchStatus = async (status: any) => {
        setBiddingQuery(status);
        setLoading(true);
        const biddingStatusData = await readRecord(
            { status, page: 0 },
            'biddings'
        );
        if (biddingStatusData.status) {
            setBiddingsData(biddingStatusData.data);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (biddings !== biddingsData) {
            setBiddingsData(biddings);
            setLoading(dashLoading);
        }
    }, [biddings]);

    const BidMap = (
        <div
            className={`mx-auto flex relative space-x-4 overflow-x-auto scrollBar scroll-smooth snap-x snap-mandatory`}
            ref={scrollRef}
        >
            {biddingsData.map((bid: any, index: number) => {
                if ((bid.status !== 'rejected' && !isTrainer) || isTrainer) {
                    return (
                        <Card
                            traineeInfo={bid.Trainee}
                            id={bid.id}
                            userRole={role}
                            key={index}
                            cardColor={'bg-white '}
                            cardType="BD"
                            title={bid.title}
                            courseDescription={bid.description}
                            userName={bid.creator_name}
                            role={bid.creator_role}
                            // date_of_training={DR.date_of_training}
                            date={
                                bid.status === 'accepted'
                                    ? bid.accepted_time
                                    : bid.status === 'pending'
                                    ? bid.quotedAt
                                    : bid.createdAt
                            }
                            phoneNumber={'+91 89897879076'}
                            bidAmount={bid.accepted_amount}
                            currency_code={'INR'}
                            width={'w-[292px]'}
                            status={isTrainer ? biddingQuery : bid.status}
                            isTrainee={!isTrainer}
                            topBid={
                                biddingQuery === 'pending'
                                    ? bid.topBidderDetails
                                    : {
                                          ...bid.Trainer,
                                          amount: bid.accepted_amount,
                                      }
                            }
                            appConfig={
                                appConfig['bidding_action'] === 'edit' &&
                                appConfig['cms_action'] === 'edit'
                            }
                        />
                    );
                }
            })}
        </div>
    );

    return (
        <div className="py-6 bg-[#F2F4F5B2] relative" id="bidding">
            <img
                src={circleGreen}
                alt="circleGreen"
                className="absolute top-0 right-0"
            />
            <div className="w-[95%] mx-auto  ">
                <div className="py-2">
                    <h1 className="sm:text-[34px] text-[38px] font-[500] text-center">
                        {userType === 'trainee'
                            ? 'Your Bids'
                            : 'Your Bid Requests'}
                    </h1>
                    <div className="w-[50px] h-[3px] rounded-full m-auto bg-gradient-to-r from-[#04D5BD] to-[#267DF8]"></div>
                </div>

                {['TI', 'FL'].includes(role) && (
                    <div className="flex items-center justify-center space-x-6 mt-6 mx-auto w-2/4">
                        <button
                            className={`${
                                biddingQuery === 'accepted'
                                    ? 'bg-[#E0FFF3] text-[#33BD87]'
                                    : 'bg-white text-[#A8ABBB]'
                            } cursor-pointer text-[14px] rounded-[8px] py-3 px-8 outline-none`}
                            onClick={() => fetchStatus('accepted')}
                        >
                            Accepted
                        </button>
                        <button
                            className={`${
                                biddingQuery === 'pending'
                                    ? 'bg-[#FFF3D2] text-[#ECB833]'
                                    : 'bg-white text-[#A8ABBB]'
                            } cursor-pointer text-[14px] rounded-[8px] py-3 px-8 outline-none`}
                            onClick={() => fetchStatus('pending')}
                        >
                            In Progress
                        </button>
                        <button
                            className={`${
                                biddingQuery === 'rejected'
                                    ? 'bg-[#FFDADA] text-[#FB5935]'
                                    : 'bg-white text-[#A8ABBB]'
                            } cursor-pointer text-[14px] rounded-[8px] py-3 px-8 outline-none`}
                            onClick={() => fetchStatus('rejected')}
                        >
                            Closed
                        </button>
                    </div>
                )}
                {/* update by me */}
                {biddingsData.length > 4 && (
                    <div className="flex justify-end">
                        <p
                            className="py-3 pr-8 cursor-pointer text-[14px] font-[500] text-[#5F5F5F] hover:text-[#267DF8]"
                            onClick={() =>
                                navigate('/request/bidding', {
                                    state: 'typeForDashboard',
                                })
                            }
                        >
                            See All
                        </p>
                    </div>
                )}

                <div className="flex items-center justify-around relative space-x-5 h-[400px]">
                    {!loading && (
                        <img
                            src={ScrollY}
                            alt="ScrollY"
                            className="cursor-pointer"
                            onClick={() =>
                                (scrollRef.current.scrollLeft -= 1000)
                            }
                        />
                    )}
                    {/* <div className="relative overflow-x-auto w-full"> */}

                    <DashBoardLoader
                        children={BidMap}
                        data={biddingsData}
                        loading={loading}
                    />
                    {/* </div> */}
                    {!loading && (
                        <img
                            src={ScrollY}
                            alt="ScrollY"
                            className="rotate-180 cursor-pointer"
                            onClick={() =>
                                (scrollRef.current.scrollLeft += 1000)
                            }
                        />
                    )}
                </div>
            </div>

            <img
                src={circleBlue}
                alt="circleBlue"
                className="absolute bottom-0 left-0"
            />
        </div>
    );
};

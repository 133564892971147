import React, { useEffect, useRef, useState } from 'react';
import filterLogo from '../../assets/Images/filterLogo.svg';
import filterLogoWhite from '../../assets/Images/filterLogoWhite.svg';
import sampleImageUpload from '../../assets/Images/sampleImageUpload.svg';
import Cal from '../../assets/Images/Cal.svg';
import mode from '../../assets/Images/mode.svg';
import session from '../../assets/Images/session.svg';
import dateImg from '../../assets/Images/dateImg.svg';
import locationImg from '../../assets/Images/locationImg.svg';
import arrow from '../../assets/Images/RightArrow.svg';
import editAll from '../../assets/Images/editAll.svg';
import TargetIcon from '../../assets/Images/TargetIcon.svg';
import phoneNumStatus from '../../assets/Images/phoneNumStatus.svg';
import rs from '../../assets/Images/rs.svg';
import profileDropDown from '../../assets/Images/profileDropDown.svg';
import { useAppSelector, useAppDispatch } from '../..';
import { useNavigate } from 'react-router-dom';
import { Accordian } from '../Elements/Accordian';
import {
    BatchTimingFilter,
    DayFilter,
    FeeFilter,
    FilterData,
} from './FilterData';
import { filterCourse } from './FilterChecker';
import { PulseLoader } from 'react-spinners';
import {
    setDirectRequest,
    setFilters,
} from '../../Redux/Reducers/directRequestReducers';
import { RoleCard } from './Role';
import { daysConversionAndSort } from '../../Services/commonFunctions';
import toast from 'react-hot-toast';
import { CompareCourseModal } from '../CompareCourseModal/CompareCourseModal';
import { setCompareCourse } from '../../Redux/Reducers/comparecoursesReducers';
import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';
import { Footer } from '../Footer/Footer';

export const SearchCourse = () => {
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const [filterChange, setFilterChange] = useState(false);
    const [loading, setLoading] = useState(true);
    const [resetFunc, setResetFunc] = useState<any>(0);
    const [sortCriteria, setSortCriteria] = useState('date'); // Default sort by date
    const { input, minAmount, maxAmount, filters } = useAppSelector(
        ({ directRequest }: any) => directRequest['Value']
    );
    const [{ role }, { appConfig }] = useAppSelector(
        ({ signin, appConfig }: any) => [
            signin['signinValue'],
            appConfig['configData'],
        ]
    );
    const userRole = ['TI', 'FL'].includes(role);
    const [courses, setCourses] = useState<any[]>([]);

    const handleFilterSubmit = () => {
        setFilterChange(!filterChange);
    };

    const handleSortChange = (e: {
        target: { value: React.SetStateAction<string> };
    }) => {
        setSortCriteria(e.target.value);
    };

    const sortCourses = (courses: any[]) => {
        switch (sortCriteria) {
            case 'date':
                return courses.sort(
                    (
                        a: { batches: { batchDate: string | number | Date }[] },

                        b: { batches: { batchDate: string | number | Date }[] }
                    ) =>
                        new Date(b.batches[0].batchDate).getTime() -
                        new Date(a.batches[0].batchDate).getTime()
                );

            case 'fee':
                return courses.sort(
                    (a: { fee: string }, b: { fee: string }) =>
                        parseFloat(a.fee) - parseFloat(b.fee)
                );

            case 'duration':
                return courses.sort(
                    (
                        a: { converted_to_days: string },

                        b: { converted_to_days: string }
                    ) =>
                        parseFloat(a.converted_to_days) -
                        parseFloat(b.converted_to_days)
                );

            default:
                return courses;
        }
    };

    useEffect(() => {
        const filteredData = filterCourse(input, filters);
        const sortedData = sortCourses(filteredData);
        setCourses(filteredData);
        if (loading) {
            setLoading(false);
        }
    }, [JSON.stringify(filters), JSON.stringify(input), sortCriteria]);

    useEffect(() => {
        if (JSON.stringify(input) !== JSON.stringify(courses)) {
            setCourses(input);
        }
    }, [JSON.stringify(input)]);

    const ClearAllFunc = () => {
        setResetFunc(Math.random());
        dispatch(setFilters(false));
    };

    const NoCourseFound = () => (
        <div className="flex flex-col min-h-[450px] items-center justify-center w-full text-gray-500">
            No course Found
            {!userRole && (
                <h1
                    className="text-[#2975FD] cursor-pointer font-medium text-[1rem]"
                    onClick={() =>
                        navigate('/request/bidding', {
                            state: 'typeForHeaderClick',
                        })
                    }
                >
                    Would you like to request a course?
                </h1>
            )}
        </div>
    );

    if (loading) {
        return (
            <div className="flex min-h-[450px] items-center justify-center w-full">
                <PulseLoader color="#0074FC" />
            </div>
        );
    }

    if (!input.length) {
        return <NoCourseFound />;
    }

    return (
        <div className="bg-[#F6F7F8] min-h-screen">
            <div className="mt-8 w-11/12 mx-auto mb-10">
                <div className="flex flex-col lg:flex-row justify-between items-center mb-4">
                    <div className="flex flex-col items-center">
                        <div
                            className={`flex justify-around p-3 mb-4 lg:mb-0 w-[115px] rounded-full cursor-pointer ${
                                filterChange || Object.keys(filters).length > 0
                                    ? 'bg-[#2975FD] text-white'
                                    : 'bg-white'
                            }`}
                            onClick={handleFilterSubmit}
                        >
                            <img
                                src={
                                    filterChange ||
                                    Object.keys(filters).length > 0
                                        ? filterLogoWhite
                                        : filterLogo
                                }
                                alt="filter"
                            />
                            <p className="text-[16px] font-[500]">Filter</p>
                        </div>
                        {Object.keys(filters).length > 0 && (
                            <p
                                className="text-[#FB5935] text-[16px] font-[500] cursor-pointer mt-4 lg:mt-0"
                                onClick={ClearAllFunc}
                            >
                                Clear All
                            </p>
                        )}
                    </div>
                    <select
                        value={sortCriteria}
                        onChange={handleSortChange}
                        className="p-2 border border-gray-300 rounded-md"
                    >
                        <option value="date">Sort by Date</option>
                        <option value="fee">Sort by Fee</option>
                        <option value="duration">Sort by Duration</option>
                    </select>
                </div>
                <div className="flex flex-col lg:flex-row pt-4 gap-y-10 lg:gap-x-10 min-h-[450px]">
                    {(filterChange || Object.keys(filters).length > 0) && (
                        <SearchFilter
                            resetFunc={resetFunc}
                            minAmount={minAmount}
                            maxAmount={maxAmount}
                            filters={filters}
                        />
                    )}
                    {courses.length ? (
                        <SearchCourseCard
                            input={courses}
                            appConfig={appConfig}
                            role={role}
                        />
                    ) : (
                        <NoCourseFound />
                    )}
                </div>
            </div>
        </div>
    );
};

const SearchFilter = ({ resetFunc, minAmount, maxAmount, filters }: any) => {
    const filterJSON = [
        {
            title: 'Trainer',
            filters: [
                { name: 'Training Institute', value: 'TI' },
                { name: 'FreeLancer', value: 'FL' },
            ],
            type: 'radio',
            filterName: 'trainer_role',
        },
        {
            title: 'Mode',
            filters: [
                { name: 'Online', value: 'online' },
                { name: 'Offline', value: 'offline' },
                { name: 'Both', value: 'both' },
            ],
            type: 'radio',
            filterName: 'mode',
        },
        {
            title: 'Days',
            filters: [
                { name: 'Sunday', value: 'sunday' },
                { name: 'Monday', value: 'monday' },
                { name: 'Tuesday', value: 'tuesday' },
                { name: 'Wednesday', value: 'wednesday' },
                { name: 'Thursday', value: 'thursday' },
                { name: 'Friday', value: 'friday' },
                { name: 'Saturday', value: 'saturday' },
            ],
            type: 'checkbox',
            filterName: 'session',
        },
        {
            title: 'Discount',
            filters: [
                { name: 'Yes', value: true },
                { name: 'No', value: false },
            ],
            type: 'radio',
            filterName: 'discount_available',
        },
        {
            title: 'Fees',
            type: 'number',
            filterName: 'fee',
        },
        {
            title: 'Batch Timing',
            type: 'time',
            filterName: 'batchTiming',
        },
        {
            title: 'Duration',
            filters: [
                { name: 'Below 1 Month', value: 'lessOneMonth' },
                { name: '1 Month - 3 Month', value: 'oneToThree' },
                { name: 'Above 3 Months', value: 'aboveThreeMonths' },
            ],
            type: 'radio',
            filterName: 'duration',
        },
        {
            title: 'Certification guidance',
            filters: [
                { name: 'Yes', value: true },
                { name: 'No', value: false },
            ],
            filterName: 'certification_guidance',
            type: 'radio',
        },
    ];
    const [activeIndex, setIndex] = useState<any>(Object.keys(filters) || []);
    const [filter, setFilter] = useState<any>([]);

    const openAndClose = (name: string) => {
        if (activeIndex.includes(name)) {
            const indexValue = activeIndex.filter((val: any) => val !== name);
            setIndex(indexValue);
        } else {
            setIndex([...activeIndex, name]);
        }
    };

    return (
        <div className="space-y-9 mt-1 w-full lg:w-4/12 xl:w-3/12">
            {filterJSON.map((filter: any, index) => {
                return (
                    <Accordian
                        minAmount={minAmount}
                        maxAmount={maxAmount}
                        key={index}
                        name={filter.title}
                        onClick={() => openAndClose(filter.filterName)}
                        openCondition={activeIndex.includes(filter.filterName)}
                    >
                        {filter.type === 'radio' && (
                            <FilterData resetFunc={resetFunc} filter={filter} />
                        )}
                        {filter.type === 'number' && (
                            <FeeFilter
                                resetFunc={resetFunc}
                                filter={filter}
                                minAmount={minAmount}
                                maxAmount={maxAmount}
                            />
                        )}
                        {filter.type === 'checkbox' && (
                            <DayFilter resetFunc={resetFunc} filter={filter} />
                        )}
                        {filter.type === 'time' && (
                            <BatchTimingFilter
                                resetFunc={resetFunc}
                                filter={filter}
                            />
                        )}
                    </Accordian>
                );
            })}
        </div>
    );
};

const SearchCourseCard = ({ input, appConfig, role }: any) => {
    const [idStatus, setIdStatus] = useState<any>([]);
    const [compareCourseInfo, setCompareCourseInfo] = useState<any>([]);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { cardState } = useAppSelector(
        ({ compare }: any) => compare['compareCoursesData']
    );

    // Ref for scrolling to selected course
    const selectedCourseRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        // Scroll to the previously selected course if it exists
        const selectedCourseId = localStorage.getItem('selectedCourseId');
        if (selectedCourseId && selectedCourseRef.current) {
            selectedCourseRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
            // Optionally, clear the saved course ID from localStorage after scrolling
            localStorage.removeItem('selectedCourseId');
        }
    }, []);

    const GetEvent = (e: any, trainee: any) => {
        const { checked } = e.target;
        if (checked) {
            dispatch(setCompareCourse({ cardState: true }));
            if (compareCourseInfo.length >= 3) {
                toast.error('Compare Maximum 3 Courses');
            } else {
                setIdStatus([...idStatus, trainee['id']]);
                setCompareCourseInfo([
                    ...compareCourseInfo,
                    {
                        checked: checked,
                        id: trainee.id,
                        name: trainee.trainer_name,
                        title: trainee.title,
                        trainer_id: trainee.trainer_id,
                        image: trainee.image,
                    },
                ]);
            }
        } else {
            const removeID = compareCourseInfo.filter(
                (idx: any) => idx['id'] !== trainee['id']
            );
            setCompareCourseInfo([...removeID]);
            const removeIdForChecked = idStatus.filter(
                (idx: any) => idx !== trainee['id']
            );
            setIdStatus([...removeIdForChecked]);
        }
    };

    const navigateToCourseView = (id: number, title: string) => {
        // Store the selected course ID in localStorage
        localStorage.setItem('selectedCourseId', id.toString());
        // Navigate to course details page
        navigate(`/course/view/${title}/${id}`);
    };

    // Helper function to sort batches by date
    const sortBatchesByDate = (batches: any[]) => {
        const currentDate = new Date();

        const futureBatches = batches.filter(
            (batch) => new Date(batch.batchDate) >= currentDate
        );
        const pastBatches = batches.filter(
            (batch) => new Date(batch.batchDate) < currentDate
        );

        // Sort future and past batches
        futureBatches.sort(
            (a, b) =>
                new Date(a.batchDate).getTime() -
                new Date(b.batchDate).getTime()
        );
        pastBatches.sort(
            (a, b) =>
                new Date(b.batchDate).getTime() -
                new Date(a.batchDate).getTime()
        );

        return [...futureBatches, ...pastBatches]; // Return sorted future and past batches
    };

    // Function to check if a course has future batches
    const hasFutureBatches = (batches: any[]) => {
        const currentDate = new Date();
        return batches.some(
            (batch) => new Date(batch.batchDate) >= currentDate
        );
    };

    // Sorting courses so that courses with future batches appear first
    const sortedCourses = [...input].sort((a: any, b: any) => {
        // Shallow copy of the array using spread operator
        const aHasFutureBatches = hasFutureBatches(a.batches);
        const bHasFutureBatches = hasFutureBatches(b.batches);

        // Sort courses with future batches first
        if (aHasFutureBatches && !bHasFutureBatches) return -1;
        if (!aHasFutureBatches && bHasFutureBatches) return 1;
        return 0; // If both have or don't have future batches, keep their original order
    });

    return (
        <div className="space-y-6 w-full">
            {cardState && (
                <CompareCourseModal
                    CourseInfo={compareCourseInfo}
                    CourseInfoChange={setCompareCourseInfo}
                    checkedStatus={idStatus}
                    checkedStatusChange={setIdStatus}
                />
            )}
            {sortedCourses.map((trainee: any, index: any) => {
                // Check if this is the previously selected course
                const isSelectedCourse =
                    trainee.id ===
                    Number(localStorage.getItem('selectedCourseId'));

                // Sort batches by future and past for each course
                const sortedBatches = sortBatchesByDate(trainee.batches);

                return (
                    <div
                        ref={isSelectedCourse ? selectedCourseRef : null} // Set the ref if this is the selected course
                        className={`bg-white flex flex-col lg:flex-row p-2 lg:h-[277px] ${
                            isSelectedCourse ? 'highlight-course' : ''
                        }`} // Optional class to highlight
                        key={index}
                    >
                        <img
                            src={trainee.image}
                            alt="sample"
                            className="w-full lg:w-[180px] xl:w-[260px] object-contain cursor-pointer"
                            onClick={() =>
                                navigateToCourseView(trainee.id, trainee.title)
                            }
                        />
                        <div className="w-full p-6">
                            <div className="flex flex-col justify-between space-y-6">
                                <div className="space-y-2 relative">
                                    {appConfig &&
                                        appConfig['comparability_action'] !==
                                            'off' && (
                                            <div className="absolute right-0 flex items-center gap-2">
                                                <input
                                                    type="checkbox"
                                                    checked={idStatus.includes(
                                                        trainee.id
                                                    )}
                                                    className="w-[21px] h-[21px] border-[1px] border-[#D9D9D9] rounded-[2px] cursor-pointer"
                                                    onChange={(e: any) =>
                                                        GetEvent(e, trainee)
                                                    }
                                                />
                                                <label className="text-[12px] font-[500] text-[#2975FD]">
                                                    Compare
                                                </label>
                                            </div>
                                        )}
                                    <div className="flex items-center gap-2">
                                        <p
                                            className="text-[23px] font-[500] cursor-pointer"
                                            onClick={() => {
                                                localStorage.setItem(
                                                    'selectedCourseUserId',
                                                    JSON.stringify(
                                                        trainee.trainer_id
                                                    )
                                                );
                                                navigate(
                                                    `/profile/view/${trainee.trainer_name}`
                                                );
                                            }}
                                        >
                                            {trainee.trainer_name}
                                        </p>
                                        <RoleCard role={trainee.trainer_role} />
                                    </div>
                                    <h1
                                        className="text-[#686868] text-[20px] font-[500] cursor-pointer"
                                        onClick={() =>
                                            navigateToCourseView(
                                                trainee.id,
                                                trainee.title
                                            )
                                        }
                                    >
                                        {trainee.title}
                                    </h1>
                                </div>

                                {/* Pass sorted batches to UserRequestDetails */}
                                <UserRequestDetails
                                    role={role}
                                    duration={trainee.duration}
                                    courseID={trainee.id}
                                    batchTimings={trainee.batch_timings}
                                    address={trainee.trainer_address}
                                    num={trainee.trainer_number}
                                    fee={trainee.fee}
                                    converted_to_days={
                                        trainee.converted_to_days
                                    }
                                    modeOf={trainee['mode_of_teaching']['mode']}
                                    sessionOf={
                                        trainee['mode_of_teaching']['session']
                                    }
                                    batches={sortedBatches}
                                    isDiscountAvailable={
                                        trainee.discount_available
                                    }
                                    discountPercentage={
                                        trainee.discount_percentage
                                    }
                                />
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const UserRequestDetails = ({
    role,
    duration,
    courseID,
    address,
    num,
    fee,
    converted_to_days,
    modeOf,
    sessionOf,
    batchTimings,
    batches,
    isDiscountAvailable,
    discountPercentage,
}: any) => {
    let text = '';
    for (let i = 0; i < sessionOf.length; i++) {
        text += sessionOf[i].slice(0, 2) + ',';
    }
    const feeConvertedToNumber = Number(fee);
    const applyDiscountAndRound = (wholeAmount: number) => {
        return (
            wholeAmount - Math.round(wholeAmount * (discountPercentage / 100))
        );
    };
    const amount = isDiscountAvailable
        ? applyDiscountAndRound(feeConvertedToNumber)
        : feeConvertedToNumber;
    const className =
        'text-[#303030] text-[13px] xl:text-[15px] font-[400] max-h-[25px]';
    return (
        <div className="space-y-4">
            <div className="flex flex-col lg:flex-row items-center">
                {modeOf === 'both' ? (
                    <Keyvalue
                        icon={mode}
                        addClass={'w-full lg:w-1/5'}
                        classType={className}
                        index={'index'}
                        extraParam={'Online, Offline'}
                    />
                ) : (
                    <Keyvalue
                        icon={mode}
                        value={modeOf}
                        addClass={'w-full lg:w-1/5'}
                        classType={className}
                        index={'index'}
                    />
                )}

                <Keyvalue
                    icon={session}
                    value={daysConversionAndSort(sessionOf)}
                    addClass={'w-full lg:w-1/5'}
                    classType={className}
                />
                <Keyvalue
                    icon={dateImg}
                    value={duration}
                    addClass={'w-full lg:w-1/5'}
                    classType={className}
                />
                <Keyvalue
                    icon={dateImg}
                    value={batches
                        .map((batch: any) => batch.batchTime)
                        .join(', ')}
                    addClass={'w-full lg:w-1/5'}
                    classType={className}
                />
            </div>
            <div className="flex flex-col lg:flex-row items-start gap-4 lg:gap-0">
                {role !== 'TI' && (
                    <Keyvalue
                        icon={TargetIcon}
                        value={amount.toLocaleString('en-IN')}
                        wholeAmount={
                            isDiscountAvailable
                                ? feeConvertedToNumber.toLocaleString('en-IN')
                                : false
                        }
                        discountPercentage={discountPercentage}
                        fee={'fee'}
                    />
                )}
                <Keyvalue
                    icon={phoneNumStatus}
                    value={num}
                    classType={className}
                    addClass={'w-full lg:w-1/5'}
                />
                <Keyvalue
                    icon={locationImg}
                    value={address}
                    classType={className}
                    addClass={'w-full lg:w-1/2'}
                />
            </div>
        </div>
    );
};

const Keyvalue = ({
    icon,
    value,
    classType,
    addClass,
    index,
    fee,
    extraParam,
    wholeAmount,
    discountPercentage,
}: any) => {
    return (
        <div
            className={`flex gap-2 items-center ${addClass} ${
                !index && 'border-l-[1px] px-3'
            }`}
        >
            <img src={icon} alt="RequestDatas" className="w-[14px]" />
            <div className="flex gap-2 items-center">
                {fee && <img src={rs} alt="rs" className="w-[8px]" />}
                {wholeAmount && (
                    <p className={`line-through text-[#303030] text-[13px]`}>
                        {wholeAmount}
                    </p>
                )}
                <p
                    className={`${classType} capitalize flex items-center shrinkToTwoLines shrinkToOneLine break-words `}
                >
                    {value} {icon === mode && 'Mode'} {extraParam && extraParam}{' '}
                    {wholeAmount && (
                        <span className="text-[12px] text-green-400 font-bold">
                            (-{discountPercentage}%)
                        </span>
                    )}
                </p>
            </div>
        </div>
    );
};

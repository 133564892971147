// import React, { useEffect, useState } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import { readRecord, postRecord } from '../../Services/backend/apiCalls';
// import toast from 'react-hot-toast';
// import arrow from '../../assets/Images/RightArrow.svg';
// import { storage } from '../../Firebase/firebase';
// import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

// export const InternshipInfo = () => {
//     const { id } = useParams();
//     const [internship, setInternship] = useState(null);
//     const [hasApplied, setHasApplied] = useState(false);
//     const [loading, setLoading] = useState(true);
//     const [applying, setApplying] = useState(false);
//     const [userIdTi, setUserIdTi] = useState(null);
//     const navigate = useNavigate();
//     const [uploadingResume, setUploadingResume] = useState(false);
//     const [resumeURL, setResumeURL] = useState(null);

//     useEffect(() => {
//         const checkIfApplied = (internshipData) => {
//             const userIdSt = localStorage.getItem('uid');
//             if (
//                 internshipData.user_idSt &&
//                 internshipData.user_idSt.includes(userIdSt)
//             ) {
//                 setHasApplied(true);
//             }
//         };

//         const fetchInternship = async () => {
//             try {
//                 const internshipData = await readRecord(
//                     {},
//                     `internships/${id}`
//                 );
//                 setInternship(internshipData);
//                 setUserIdTi(internshipData.user_id);
//                 checkIfApplied(internshipData);
//                 console.log(internshipData);
//             } catch (error) {
//                 console.error('Error fetching internship details:', error);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchInternship();
//     }, [id]);

//     const updateInternshipWithUserId = async () => {
//         const userIdSt = localStorage.getItem('uid');
//         if (!userIdSt || !resumeURL) return;

//         try {
//             await postRecord(
//                 { user_idSt: userIdSt },
//                 `internships/${id}/update-user`
//             );

//             await postRecord(
//                 { resume: resumeURL },
//                 `profiles/${userIdSt}/update-resume`
//             );
//         } catch (error) {
//             console.error('Error updating internship or profile:', error);
//         }
//     };

//     const afterApplySuccess = () => {
//         updateInternshipWithUserId();
//     };

//     const handleApplyNow = async () => {
//         setApplying(true);
//         try {
//             const userIdSt = localStorage.getItem('uid');
//             const response = await postRecord(
//                 { internshipId: id, userIdTi, userIdSt },
//                 'apply-internship'
//             );

//             if (response.status) {
//                 toast.success('Application successfully sent.');
//                 setHasApplied(true);
//                 afterApplySuccess();
//                 navigate('/dashboard');
//             } else {
//                 alert('Failed to apply for internship.');
//             }
//         } catch (error) {
//             console.error('Error applying for internship:', error);
//             alert('An error occurred while applying for the internship.');
//         } finally {
//             setApplying(false);
//         }
//     };

//     const handleResumeUpload = (file) => {
//         const fileName = `${id}-${new Date().getTime()}_resume`;
//         const storageRef = ref(storage, `/resumes/${fileName}`);
//         setUploadingResume(true);

//         const uploadTask = uploadBytesResumable(storageRef, file);
//         uploadTask.on(
//             'state_changed',
//             () => {},
//             (error) => {
//                 setUploadingResume(false);
//                 console.error('Error uploading resume:', error);
//                 toast.error('Failed to upload resume.');
//             },
//             () => {
//                 getDownloadURL(uploadTask.snapshot.ref).then((url) => {
//                     setResumeURL(url);
//                     setUploadingResume(false);
//                     toast.success('Resume uploaded successfully!');
//                 });
//             }
//         );
//     };

//     if (loading) {
//         return (
//             <div className="flex items-center justify-center min-h-screen">
//                 <div className="loader ease-linear rounded-full border-4 border-t-4 border-blue-500 h-12 w-12 mb-10"></div>
//             </div>
//         );
//     }

//     if (!internship) {
//         return (
//             <p className="text-center text-red-500">
//                 Failed to load internship details.
//             </p>
//         );
//     }

//     return (
//         <div className="container mx-auto px-4 py-8">
//             <div className="flex gap-2 items-center mt-5 w-full mx-auto cursor-pointer mb-4">
//                 <img
//                     src={arrow}
//                     alt="ArrowForBack"
//                     className="rotate-180 w-[17px] h-[17px]"
//                     onClick={() => navigate(-1)}
//                 />
//                 <p
//                     className="text-[16px] sm:text-[18px] font-[400] text-[#3061F4]"
//                     onClick={() => navigate(-1)}
//                 >
//                     Back
//                 </p>
//             </div>
//             <div className="flex flex-col md:flex-row justify-between items-start space-y-8 md:space-y-0">
//                 <div className="md:w-2/3 mr-5">
//                     <img
//                         src={
//                             internship.thumbnail ||
//                             'https://via.placeholder.com/500x300?text=Internship+Image'
//                         }
//                         alt={internship.title}
//                         className="w-full h-48 object-cover rounded-lg mb-4"
//                     />
//                     <h1 className="text-3xl font-bold mb-2 mt-20">
//                         {internship.title || 'Internship Title'}
//                     </h1>
//                     <p className="mb-1">
//                         <strong>Company: </strong>
//                         {internship.company || 'N/A'}
//                     </p>
//                     <p className="text-lg mb-2 border-b pb-4 text-justify">
//                         <strong>Description: </strong>
//                         {internship.description || 'No description available.'}
//                     </p>
//                     <h2 className="text-lg font-semibold mb-2">Highlights</h2>
//                     <p>
//                         <strong>Days:</strong>{' '}
//                         {internship.days?.join(', ') || 'Not specified'}
//                     </p>
// <p>
//     <strong>Location:</strong>{' '}
//     {internship.location || 'Not specified'}
// </p>
//                     <p>
//                         <strong>Active:</strong>{' '}
//                         {internship.is_active ? 'Yes' : 'No'}
//                     </p>
//                     {/* <p>
//                         <strong>Start Date:</strong>
//                         {new Date(internship.start_date).toLocaleDateString(
//                             'en-US',
//                             { year: 'numeric', month: 'short', day: 'numeric' }
//                         )}
//                     </p> */}
//                     <p>
//                         <strong>Start Date:</strong>{' '}
//                         {(() => {
//                             try {
//                                 const date = new Date(internship.start_date);
//                                 return date.getTime() > 0
//                                     ? date.toLocaleDateString('en-US', {
//                                           year: 'numeric',
//                                           month: 'short',
//                                           day: 'numeric',
//                                       })
//                                     : 'No date provided';
//                             } catch {
//                                 return 'No date provided';
//                             }
//                         })()}
//                     </p>
//                     <div className="mt-6 space-y-2">
//                         <p>
//                             <strong>Application Deadline: </strong>
//                             {new Date(
//                                 internship.application_deadline
//                             ).toLocaleDateString('en-US', {
//                                 year: 'numeric',
//                                 month: 'short',
//                                 day: 'numeric',
//                             })}
//                         </p>
//                         <p>
//                             <strong>Requirements:</strong>
//                             {internship.requirements || 'Not specified'}
//                         </p>
//                     </div>
//                 </div>

//                 <div className="md:w-1/3 bg-white shadow-lg rounded-lg p-6">
//                     <h2 className="text-xl font-semibold mb-4">
//                         Internship Details
//                     </h2>
//                     <div className="space-y-2">
//                         <p>
//                             <strong>Mode:</strong>{' '}
//                             {internship.mode || 'Not specified'}
//                         </p>
//                         <p>
//                             <strong>Type:</strong>{' '}
//                             {internship.internship_type || 'Not specified'}
//                         </p>
//                         <p>
//                             <strong>Certificate:</strong>{' '}
//                             {internship.certificate ? 'Yes' : 'No'}
//                         </p>
// <p>
//     <strong>Duration:</strong>
//     {internship.duration
//         ? `${internship.duration} ${internship.duration_unit}`
//         : 'Not specified'}
// </p>
//                         {/* <p>
//                             <strong>Stipend:</strong>
//                             {internship.stipend
//                                 ? `${internship.stipend.toLocaleString()} INR ${
//                                       internship.stipend_frequency
//                                   }`
//                                 : 'Not Provided'}
//                         </p> */}
//                         <p>
//                             <strong>
//                                 {internship.internship_type === 'Stipend'
//                                     ? 'Stipend: '
//                                     : internship.internship_type === 'Paid'
//                                     ? 'Internship Fees: '
//                                     : 'Stipend: '}
//                             </strong>
//                             {internship.stipend
//                                 ? `${internship.stipend.toLocaleString()} INR ${
//                                       internship.stipend_frequency
//                                   }`
//                                 : 'Not Provided'}
//                         </p>
//                         <div className="form-group">
//                             <label className="block mb-2 font-semibold">
//                                 Upload Resume
//                             </label>
//                             <input
//                                 type="file"
//                                 onChange={(e) =>
//                                     e.target.files &&
//                                     handleResumeUpload(e.target.files[0])
//                                 }
//                                 className="w-full px-4 py-2 border border-gray-300 rounded"
//                                 disabled={hasApplied} // Disable if already applied
//                                 accept="application/pdf"
//                             />
//                             {uploadingResume && <p>Uploading resume...</p>}
//                             {resumeURL && (
//                                 <p className="text-green-500">
//                                     Resume uploaded successfully.
//                                 </p>
//                             )}
//                         </div>
//                     </div>

//                     <div className="mt-6">
//                         {hasApplied ? (
//                             <p className="text-gray-600">
//                                 You have already applied for this internship.
//                             </p>
//                         ) : (
//                             <button
//                                 onClick={handleApplyNow}
//                                 className={`w-full py-2 rounded-md transition ease-in-out ${
//                                     applying || !resumeURL
//                                         ? 'bg-gray-400 text-gray-700 cursor-not-allowed text-white'
//                                         : 'bg-blue-500 text-white hover:bg-blue-600'
//                                 }`}
//                                 disabled={applying || !resumeURL}
//                             >
//                                 {applying ? 'Applying...' : 'Apply Now'}
//                             </button>
//                         )}
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default InternshipInfo;

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { readRecord, postRecord } from '../../Services/backend/apiCalls';
import toast from 'react-hot-toast';
import arrow from '../../assets/Images/RightArrow.svg';
import { storage } from '../../Firebase/firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import mode from '../../assets/Images/mode.svg';
import certification from '../../assets/Images/certification.svg';
import rs from '../../assets/Images/rs.svg';

export const InternshipInfo = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [internship, setInternship] = useState(null);
    const [hasApplied, setHasApplied] = useState(false);
    const [loading, setLoading] = useState(true);
    const [applying, setApplying] = useState(false);
    const [userIdTi, setUserIdTi] = useState(null);
    const [uploadingResume, setUploadingResume] = useState(false);
    const [resumeURL, setResumeURL] = useState(null);

    useEffect(() => {
        const checkIfApplied = (internshipData) => {
            const userIdSt = localStorage.getItem('uid');
            if (
                internshipData.user_idSt &&
                internshipData.user_idSt.includes(userIdSt)
            ) {
                setHasApplied(true);
            }
        };

        const fetchInternship = async () => {
            try {
                const internshipData = await readRecord(
                    {},
                    `internships/${id}`
                );

                setInternship(internshipData);
                setUserIdTi(internshipData.user_id);
                checkIfApplied(internshipData);
            } catch (error) {
                console.error('Error fetching internship:', error);
                toast.error('Failed to load internship details');
            } finally {
                setLoading(false);
            }
        };

        fetchInternship();
    }, [id]);

    const updateInternshipWithUserId = async () => {
        const userIdSt = localStorage.getItem('uid');
        if (!userIdSt || !resumeURL) return;

        try {
            await postRecord(
                { user_idSt: userIdSt },
                `internships/${id}/update-user`
            );

            await postRecord(
                { resume: resumeURL },
                `profiles/${userIdSt}/update-resume`
            );
            // toast.success('Profile updated successfully');
        } catch (error) {
            console.error('Error updating details:', error);
            toast.error('Failed to update details');
        }
    };

    const handleResumeUpload = (file) => {
        if (!file) return;

        const fileName = `${id}-${new Date().getTime()}_resume`;
        const storageRef = ref(storage, `/resumes/${fileName}`);
        setUploadingResume(true);

        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
            'state_changed',
            null,
            (error) => {
                console.error('Upload error:', error);
                setUploadingResume(false);
                toast.error('Resume upload failed');
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    setResumeURL(url);
                    setUploadingResume(false);
                    toast.success('Resume uploaded successfully!');
                });
            }
        );
    };

    const handleApplyNow = async () => {
        if (!resumeURL) {
            toast.error('Please upload your resume first');
            return;
        }

        setApplying(true);
        try {
            const userIdSt = localStorage.getItem('uid');
            const response = await postRecord(
                { internshipId: id, userIdTi, userIdSt },
                'apply-internship'
            );

            if (response.status) {
                toast.success('Application sent successfully!');
                setHasApplied(true);
                updateInternshipWithUserId();
                navigate('/dashboard');
            } else {
                toast.error('Application failed');
            }
        } catch (error) {
            console.error('Application error:', error);
            toast.error('Failed to submit application');
        } finally {
            setApplying(false);
        }
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-[#3061F4] h-12 w-12"></div>
            </div>
        );
    }

    if (!internship) {
        return (
            <div className="text-center text-red-500 py-8">
                Failed to load internship details
            </div>
        );
    }

    const InternshipDetails = [
        {
            image: mode,
            text: 'Mode',
            value: internship.mode || 'Not specified',
        },

        {
            image: certification,
            text: 'Certification',
            value: internship.certificate ? 'Yes' : 'No',
        },
        {
            image: rs,
            text:
                internship.internship_type === 'Paid'
                    ? 'Internship Fee'
                    : internship.internship_type === 'Free'
                    ? 'Stipend/Fee'
                    : 'Stipend',
            value:
                internship.internship_type === 'Paid'
                    ? `₹${internship.stipend?.toLocaleString()}`
                    : internship.internship_type === 'Free'
                    ? 'Zero'
                    : internship.stipend
                    ? `₹${internship.stipend?.toLocaleString()} ${
                          internship.stipend_frequency
                      }`
                    : 'Unpaid',
        },
    ];

    const formatDate = (dateString) => {
        if (!dateString) return 'Not specified';
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        });
    };

    return (
        // <div className="min-h-screen bg-[#F5F5F5] py-8">
        <div className="flex flex-col min-h-screen bg-[#F5F5F5]">
            <div className=" bg-[#F5F5F5] py-8 flex-1  w-full">
                <div className="container mx-auto px-4">
                    {/* Back Navigation */}
                    <div
                        className="flex gap-2 items-center mb-6 cursor-pointer"
                        onClick={() => navigate(-1)}
                    >
                        <img
                            src={arrow}
                            alt="Back"
                            className="rotate-180 w-[17px] h-[17px]"
                        />
                        <span className="text-[16px] font-[400] text-[#3061F4]">
                            Back
                        </span>
                    </div>

                    <div className="flex flex-col md:flex-row gap-8">
                        {/* Main Content Section */}
                        <div className="w-full md:w-8/12">
                            <div className="min-h-[666px] bg-[#FBFBFB] rounded-lg shadow-sm">
                                {/* Internship Image */}
                                <img
                                    src={
                                        internship.thumbnail ||
                                        'default-placeholder-url'
                                    }
                                    alt={internship.title}
                                    className="w-full h-[310px] object-cover p-2"
                                />

                                {/* Content Container */}
                                <div className="flex flex-col w-11/12 mx-auto py-4">
                                    {/* Title and Company */}
                                    <h1 className="text-[23px] font-[500] mb-4">
                                        {internship.title}
                                    </h1>
                                    {internship.tags &&
                                        internship.tags.length > 0 && (
                                            <div className="flex items-center space-x-4 py-2 overflow-x-auto">
                                                {internship.tags.map(
                                                    (tag, index) => (
                                                        <p
                                                            key={index}
                                                            className="bg-[#F8F8F8] text-[#3061F4] font-[400] text-[12px] px-4 py-1 rounded-[18px]"
                                                        >
                                                            #{tag}
                                                        </p>
                                                    )
                                                )}
                                            </div>
                                        )}
                                    <div className="flex items-center space-x-4 py-2">
                                        <h2 className="text-[16px] text-[#636363] font-[500]">
                                            {internship.company}
                                        </h2>
                                    </div>

                                    {/* Tags */}

                                    {/* Description */}
                                    <div className="mt-4 text-[16px] text-[#898989] font-[300]">
                                        {internship.description}
                                    </div>

                                    <div className="border-b-2 w-full mt-6"></div>

                                    {/* Highlights Section */}
                                    <h2 className="text-[16px] font-[400] text-[#3061F4] mt-4 bold">
                                        Highlights
                                    </h2>

                                    <div className="mt-4 space-y-3 text-[16px] text-[#898989] font-[300]">
                                        {/* <p>
                                            <strong>
                                                <b>Duration:</b>{' '}
                                            </strong>{' '}
                                            {internship.duration}{' '}
                                            {internship.duration_unit}
                                        </p> */}
                                        <p>
                                            <strong>
                                                {' '}
                                                <b> Duration: </b>
                                            </strong>
                                            {internship.duration
                                                ? `${internship.duration} ${internship.duration_unit}`
                                                : 'Not specified'}
                                        </p>

                                        {/* <p>
                                            <strong>
                                                <b>Location:</b>{' '}
                                            </strong>{' '}
                                            {internship.location || 'Remote'}
                                        </p> */}
                                        <p>
                                            <strong>
                                                {' '}
                                                <b>Location: </b>{' '}
                                            </strong>{' '}
                                            {internship.location ||
                                                'Not specified'}
                                        </p>

                                        <p>
                                            <strong>
                                                {' '}
                                                <b>Start Date:</b>{' '}
                                            </strong>{' '}
                                            {formatDate(internship.start_date)}
                                        </p>
                                        {/* <p>
                                            <strong>
                                                <b>Timings:</b>{' '}
                                            </strong>{' '}
                                            {internship.start_time} -{' '}
                                            {internship.end_time}
                                        </p> */}
                                        <p>
                                            <strong>
                                                <b>Timings:</b>{' '}
                                            </strong>
                                            {internship.start_time
                                                ? new Date(
                                                      `1970-01-01T${internship.start_time}Z`
                                                  ).toLocaleTimeString([], {
                                                      hour: '2-digit',
                                                      minute: '2-digit',
                                                  })
                                                : 'N/A'}{' '}
                                            -
                                            {internship.end_time
                                                ? new Date(
                                                      `1970-01-01T${internship.end_time}Z`
                                                  ).toLocaleTimeString([], {
                                                      hour: '2-digit',
                                                      minute: '2-digit',
                                                  })
                                                : 'N/A'}
                                        </p>
                                        <p>
                                            <strong>
                                                <b>Days:</b>{' '}
                                            </strong>{' '}
                                            {internship.days.join(', ')}
                                        </p>
                                    </div>

                                    {/* Requirements Section */}
                                    {internship.requirements && (
                                        <>
                                            <h2 className="text-[16px] font-[400] text-[#3061F4] mt-6">
                                                Requirements
                                            </h2>
                                            <div className="mt-2 text-[16px] text-[#898989] font-[300]">
                                                {internship.requirements}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Right Side Panel */}
                        <div className="w-full md:w-4/12">
                            <div className="bg-white rounded-lg shadow-sm p-6">
                                <h2 className="text-xl font-semibold mb-6">
                                    Internship Details
                                </h2>

                                {/* Details List */}
                                <div className="flex flex-col space-y-4">
                                    {InternshipDetails.map((detail, index) => (
                                        <div
                                            key={index}
                                            className="flex items-center px-2 py-2 border-b border-dashed"
                                        >
                                            <div className="flex items-center gap-2 w-1/2">
                                                <img
                                                    src={detail.image}
                                                    alt={detail.text}
                                                    className="w-[18px] h-[18px]"
                                                />
                                                <span className="text-[15px] font-medium">
                                                    {detail.text}
                                                </span>
                                            </div>
                                            <span className="text-[13px] font-semibold capitalize">
                                                {detail.value}
                                            </span>
                                        </div>
                                    ))}

                                    {/* Application Deadline */}
                                    <div className="mt-4 space-y-4">
                                        <p className="text-[14px] mb-2">
                                            <strong>
                                                Application Deadline:
                                            </strong>
                                        </p>

                                        <p className="text-[#3061F4] text-[13px]">
                                            {formatDate(
                                                internship.application_deadline
                                            )}
                                        </p>
                                    </div>

                                    {/* Resume Upload Section */}
                                    <div className="mt-4">
                                        <label className="block mb-2 text-[14px] font-medium">
                                            Upload Resume (PDF)
                                        </label>
                                        <input
                                            type="file"
                                            onChange={(e) =>
                                                e.target.files &&
                                                handleResumeUpload(
                                                    e.target.files[0]
                                                )
                                            }
                                            className="w-full px-4 py-2 border border-gray-300 rounded text-[14px]"
                                            disabled={hasApplied}
                                            accept="application/pdf"
                                        />
                                        {uploadingResume && (
                                            <p className="text-[13px] text-[#898989] mt-2">
                                                Uploading resume...
                                            </p>
                                        )}
                                        {resumeURL && (
                                            <p className="text-[13px] text-green-500 mt-2">
                                                Resume uploaded successfully
                                            </p>
                                        )}
                                    </div>

                                    {/* Apply Button */}
                                    <button
                                        onClick={handleApplyNow}
                                        disabled={
                                            applying || !resumeURL || hasApplied
                                        }
                                        className={`w-full py-3 rounded-md text-[15px] font-medium transition-colors mt-6 ${
                                            applying || !resumeURL || hasApplied
                                                ? 'bg-gray-300 text-gray-600 cursor-not-allowed'
                                                : 'bg-[#3061F4] text-white hover:bg-blue-600'
                                        }`}
                                    >
                                        {hasApplied
                                            ? 'Already Applied'
                                            : applying
                                            ? 'Applying...'
                                            : 'Apply Now'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InternshipInfo;

// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import arrow from '../../assets/Images/RightArrow.svg';
// import { FaEdit } from 'react-icons/fa'; // Import an edit icon
// import { readRecord } from '../../Services/backend/apiCalls';
// import { DashBoardLoader } from '../Dashboard/NoDataChecker';
// import toast from 'react-hot-toast';

// const InternshipCard = () => {
//     const [internships, setInternships] = useState([]);
//     const [userId, setUserId] = useState('');
//     const [loading, setLoading] = useState(true); // Loading state for DashBoardLoader
//     const [showUserProfile, setShowUserProfile] = useState(false);
//     const navigate = useNavigate();

//     // Fetch user_id (uid) directly from local storage
//     useEffect(() => {
//         const storedUserId = localStorage.getItem('uid');
//         if (storedUserId) {
//             setUserId(storedUserId);
//         }
//     }, []);

//     // Fetch internships from API
//     useEffect(() => {
//         const fetchInternships = async () => {
//             if (!userId) return;

//             setLoading(true); // Start loading
//             try {
//                 const data = await readRecord({ userId }, 'ti-internships');
//                 if (data.success) {
//                     setInternships(data.data);
//                     console.log(data.data);
//                 } else {
//                     console.error('Data fetching unsuccessful:', data.message);
//                     toast.error('Failed to load internships data');
//                 }
//             } catch (error) {
//                 console.error('Error fetching internship data:', error);
//                 toast.error('Error fetching internship data');
//             } finally {
//                 setLoading(false); // End loading
//             }
//         };

//         fetchInternships();
//     }, [userId]);

//     const handleCardClick = (internshipId) => {
//         navigate(`/internshipTi/${internshipId}`);
//     };

//     const toggleUserProfile = () => {
//         setShowUserProfile(true);
//     };

//     const handleBackToInternships = () => {
//         setShowUserProfile(false);
//     };

//     return (
//         <DashBoardLoader loading={loading} data={internships}>
//             <div className="p-6">
//                 {/* Conditionally Render UserProfile or InternshipCard */}
//                 {showUserProfile ? (
//                     <UserProfile onBack={handleBackToInternships} />
//                 ) : (
//                     <div>
//                         <div className="flex items-center">
//                             <span className="mr-1">
//                                 Check Who applied for the Internships
//                             </span>
//                             <img
//                                 src={arrow}
//                                 alt="ArrowForBack"
//                                 className="w-[17px] h-[17px] rotate-90"
//                             />
//                         </div>

//                         <button
//                             className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
//                             onClick={toggleUserProfile}
//                         >
//                             Show Students
//                         </button>
//                         <p className="mt-5 border-b-2 pb-2">
//                             <b>Internships you created</b>
//                         </p>
//                         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
//                             {internships.length === 0 ? (
//                                 <p>No internships available</p>
//                             ) : (
//                                 internships.map((internship) => (
//                                     <div
//                                         key={internship.id}
//                                         className="relative bg-white shadow-md rounded-lg overflow-hidden cursor-pointer transition-transform transform hover:scale-105 hover:shadow-lg"
//                                         onClick={() =>
//                                             handleCardClick(internship.id)
//                                         }
//                                     >
//                                         <img
//                                             src={internship.thumbnail}
//                                             alt={internship.title}
//                                             className="w-full h-40 object-cover"
//                                         />
//                                         <div className="p-4">
//                                             <h3 className="text-xl font-bold">
//                                                 {internship.title}
//                                             </h3>
//                                             <p className="text-gray-600">
//                                                 {internship.company}
//                                             </p>
//                                             {/* <p className="text-gray-500">
//                                                 Started Date on:{' '}
//                                                 {new Date(
//                                                     internship.start_date
//                                                 ).toDateString()}
//                                             </p> */}
//                                             <p className="text-gray-500">
//                                                 Started Date on:{' '}
//                                                 {(() => {
//                                                     const date =
//                                                         internship.start_date;
//                                                     if (!date)
//                                                         return 'No date provided';

//                                                     const parsedDate = new Date(
//                                                         date
//                                                     );
//                                                     return parsedDate.getTime() >
//                                                         0
//                                                         ? parsedDate.toDateString()
//                                                         : 'No date provided';
//                                                 })()}
//                                             </p>
//                                             <p className="text-gray-500">
//                                                 Location: {internship.location}
//                                             </p>
//                                             <p className="text-gray-500">
//                                                 Active:{' '}
//                                                 {internship.is_active
//                                                     ? 'Yes'
//                                                     : 'No'}
//                                             </p>
//                                             <p className="text-gray-500">
//                                                 Internship Type:{' '}
//                                                 {internship.internship_type}
//                                             </p>
//                                         </div>

//                                         {/* Full overlay */}
//                                         <div className="absolute inset-0 bg-black bg-opacity-40 opacity-0 transition-opacity hover:opacity-100 flex items-center justify-center">
//                                             <FaEdit
//                                                 className="text-white text-3xl"
//                                                 title="Edit internship"
//                                             />
//                                         </div>
//                                     </div>
//                                 ))
//                             )}
//                         </div>
//                     </div>
//                 )}
//             </div>
//         </DashBoardLoader>
//     );
// };

// export default InternshipCard;

// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import arrow from '../../assets/Images/RightArrow.svg';
// import { FaEdit, FaArrowRight } from 'react-icons/fa'; // Import icons
// import { readRecord } from '../../Services/backend/apiCalls';
// import { DashBoardLoader } from '../Dashboard/NoDataChecker';
// import toast from 'react-hot-toast';

// const InternshipCard = () => {
//     const [internships, setInternships] = useState([]);
//     const [userId, setUserId] = useState('');
//     const [loading, setLoading] = useState(true); // Loading state for DashBoardLoader
//     const [showUserProfile, setShowUserProfile] = useState(false);
//     const navigate = useNavigate();

//     // Fetch user_id (uid) directly from local storage
//     useEffect(() => {
//         const storedUserId = localStorage.getItem('uid');
//         if (storedUserId) {
//             setUserId(storedUserId);
//         }
//     }, []);

//     // Fetch internships from API
//     useEffect(() => {
//         const fetchInternships = async () => {
//             if (!userId) return;

//             setLoading(true); // Start loading
//             try {
//                 const data = await readRecord({ userId }, 'ti-internships');
//                 console.log(data);

//                 if (data.success) {
//                     setInternships(data.data);
//                 } else {
//                     console.error('Data fetching unsuccessful:', data.message);
//                     toast.error('Failed to load internships data');
//                 }
//             } catch (error) {
//                 console.error('Error fetching internship data:', error);
//                 toast.error('Error fetching internship data');
//             } finally {
//                 setLoading(false); // End loading
//             }
//         };

//         fetchInternships();
//     }, [userId]);

//     const handleCardClick = (internshipId) => {
//         navigate(`/internshipTi/${internshipId}`);
//     };

//     const toggleUserProfile = () => {
//         setShowUserProfile(true);
//     };

//     const handleBackToInternships = () => {
//         setShowUserProfile(false);
//     };

//     return (
//         <DashBoardLoader loading={loading} data={internships}>
//             <div className="p-6 ">
//                 {/* Conditionally Render UserProfile or InternshipCard */}
//                 {showUserProfile ? (
//                     <UserProfile onBack={handleBackToInternships} />
//                 ) : (
//                     <div>
//                         <div className="flex items-center">
//                             <span className="mr-1">
//                                 Check Who applied for the Internships
//                             </span>
//                             <img
//                                 src={arrow}
//                                 alt="ArrowForBack"
//                                 className="w-[17px] h-[17px] rotate-90"
//                             />
//                         </div>

//                         <button
//                             className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
//                             onClick={toggleUserProfile}
//                         >
//                             Show Students
//                         </button>
//                         <p className="mt-5 border-b-2 pb-2">
//                             {/* <b>Internships you created</b> */}
//                         </p>
//                         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-6 ">
//                             {internships.length === 0 ? (
//                                 <p>No internships available</p>
//                             ) : (
//                                 internships.map((internship) => (
//                                     <div
//                                         key={internship.id}
//                                         className="relative bg-white   overflow-hidden cursor-pointer transition-transform transform  "
//                                         onClick={() =>
//                                             handleCardClick(internship.id)
//                                         }
//                                     >
//                                         {/* Top Image or Icon */}
//                                         <img
//                                             src={
//                                                 internship.thumbnail ||
//                                                 '/default-image.jpg'
//                                             }
//                                             alt={internship.title}
//                                             className="w-full h-40 object-cover"
//                                         />
//                                         <div className="p-4">
//                                             {/* Title and Institute */}
//                                             <h3 className="text-xl font-bold">
//                                                 {internship.title}
//                                             </h3>
//                                             <div className="flex items-center mt-2 mb-3">
//                                                 <p className="text-black mr-2  font-medium ">
//                                                     {internship.company}
//                                                 </p>
//                                                 {/* Institute Badge */}
//                                                 <span className="bg-blue-100 text-blue-600 text-xs font-normal px-2.5 py-0.5  rounded-full">
//                                                     Institute
//                                                 </span>
//                                             </div>

//                                             {/* Date */}
//                                             {/* <p className="text-gray-500 mt-1">
//                                                 Posted on:{' '}
//                                                 {internship.posted_date
//                                                     ? new Date(
//                                                           internship.posted_date
//                                                       ).toLocaleDateString()
//                                                     : 'Date not available'}
//                                             </p> */}
//                                             <div className="flex items-center  text-sm">
//                                                 <span className="text-gray-700">
//                                                     Posted on
//                                                 </span>
//                                                 <span className=" text-black ml-2 font-semibold">
//                                                     {(() => {
//                                                         const date = new Date(
//                                                             internship.createdAt
//                                                         );
//                                                         const day = date
//                                                             .getDate()
//                                                             .toString()
//                                                             .padStart(2, '0');
//                                                         const month =
//                                                             date.toLocaleString(
//                                                                 'default',
//                                                                 {
//                                                                     month: 'short',
//                                                                 }
//                                                             );
//                                                         const year = date
//                                                             .getFullYear()
//                                                             .toString()
//                                                             .slice(-2);
//                                                         return `${day}-${month}-${year}`;
//                                                     })()}
//                                                 </span>
//                                             </div>
//                                             <div className="   bg-slate-300    border-b-2 mt-4 w-11/12 mb-8 "></div>

//                                             {/* <p className="text-gray-500 mt-2">
//                                                 Location:{' '}
//                                                 {internship.location ||
//                                                     'Not specified'}
//                                             </p>
//                                             <p className="text-gray-500">
//                                                 Internship Type:{' '}
//                                                 {internship.internship_type ||
//                                                     'N/A'}
//                                             </p> */}
//                                         </div>

//                                         {/* Arrow Icon */}
//                                         <div className="absolute bottom-4   right-10">
//                                             {/* <FaArrowRight className="text-blue-500 text-xl" /> */}
//                                             <img
//                                                 src={arrow}
//                                                 alt="ArrowForBack"
//                                                 className="w-[17px] h-[17px] rotate-0"
//                                             />
//                                         </div>
//                                     </div>
//                                 ))
//                             )}
//                         </div>
//                     </div>
//                 )}
//             </div>
//         </DashBoardLoader>
//     );
// };

// export default InternshipCard;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import arrow from '../../assets/Images/RightArrow.svg';
import { FaEdit, FaArrowRight } from 'react-icons/fa';
import { readRecord } from '../../Services/backend/apiCalls';
import { DashBoardLoader } from '../Dashboard/NoDataChecker';
import toast from 'react-hot-toast';

const InternshipCard = () => {
    const [internships, setInternships] = useState([]);
    const [userId, setUserId] = useState('');
    const [loading, setLoading] = useState(true);
    const [showUserProfile, setShowUserProfile] = useState(false);
    const [activeFilter, setActiveFilter] = useState('active');
    const navigate = useNavigate();

    useEffect(() => {
        const storedUserId = localStorage.getItem('uid');
        if (storedUserId) {
            setUserId(storedUserId);
        }
    }, []);

    useEffect(() => {
        const fetchInternships = async () => {
            if (!userId) return;
            setLoading(true);
            try {
                const data = await readRecord({ userId }, 'ti-internships');
                if (data.success) {
                    setInternships(data.data);
                } else {
                    console.error('Data fetching unsuccessful:', data.message);
                    toast.error('Failed to load internships data');
                }
            } catch (error) {
                console.error('Error fetching internship data:', error);
                toast.error('Error fetching internship data');
            } finally {
                setLoading(false);
            }
        };
        fetchInternships();
    }, [userId]);

    const filteredInternships = internships.filter((internship) =>
        activeFilter === 'active' ? internship.is_active : !internship.is_active
    );

    const handleCardClick = (internshipId) => {
        navigate(`/internshipTi/${internshipId}`);
    };

    const toggleUserProfile = () => {
        setShowUserProfile(true);
    };

    const handleBackToInternships = () => {
        setShowUserProfile(false);
    };

    return (
        <DashBoardLoader loading={loading} data={internships}>
            <div className="p-6">
                {showUserProfile ? (
                    <UserProfile onBack={handleBackToInternships} />
                ) : (
                    <div className="space-y-6">
                        {/* Toggle Buttons */}
                        {/* <div className="flex space-x-2">
                            <button
                                onClick={() => setActiveFilter('active')}
                                className={`px-4 py-2 rounded-md transition-colors ${
                                    activeFilter === 'active'
                                        ? 'bg-blue-100 text-blue-600'
                                        : 'bg-white text-gray-600 hover:bg-gray-50'
                                }`}
                            >
                                Active
                            </button>
                            <button
                                onClick={() => setActiveFilter('inactive')}
                                className={`px-4 py-2 rounded-md transition-colors ${
                                    activeFilter === 'inactive'
                                        ? 'bg-blue-100 text-blue-600'
                                        : 'bg-white text-gray-600 hover:bg-gray-50'
                                }`}
                            >
                                Inactive
                            </button>
                        </div> */}

                        <div className="flex items-center">
                            <span className="mr-1">
                                Check Who applied for the Internships
                            </span>
                            <img
                                src={arrow}
                                alt="ArrowForBack"
                                className="w-[17px] h-[17px] rotate-90"
                            />
                        </div>

                        <button
                            className="px-4 py-2 bg-blue-500 text-white rounded"
                            onClick={toggleUserProfile}
                        >
                            Show Students
                        </button>

                        <p className="border-b-2 pb-2"></p>

                        <div className="flex space-x-2 flex-1 justify-center gap-4">
                            <button
                                onClick={() => setActiveFilter('active')}
                                className={`px-4 py-2 rounded-md transition-colors ${
                                    activeFilter === 'active'
                                        ? 'bg-blue-100 text-blue-600'
                                        : 'bg-white text-gray-600 hover:bg-gray-50'
                                }`}
                            >
                                Active
                            </button>
                            <button
                                onClick={() => setActiveFilter('inactive')}
                                className={`px-4 py-2 rounded-md transition-colors ${
                                    activeFilter === 'inactive'
                                        ? 'bg-blue-100 text-blue-600'
                                        : 'bg-white text-gray-600 hover:bg-gray-50'
                                }`}
                            >
                                Inactive
                            </button>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                            {filteredInternships.length === 0 ? (
                                <p>No internships available</p>
                            ) : (
                                filteredInternships.map((internship) => (
                                    <div
                                        key={internship.id}
                                        className="relative bg-white overflow-hidden cursor-pointer transition-transform transform "
                                        onClick={() =>
                                            handleCardClick(internship.id)
                                        }
                                    >
                                        <img
                                            src={
                                                internship.thumbnail ||
                                                '/default-image.jpg'
                                            }
                                            alt={internship.title}
                                            className="w-full h-40 object-cover"
                                        />
                                        <div className="p-4">
                                            <h3 className="text-xl font-bold">
                                                {internship.title}
                                            </h3>
                                            <div className="flex items-center mt-2 mb-3">
                                                <p className="text-black mr-2 font-medium">
                                                    {internship.company}
                                                </p>
                                                <span className="bg-blue-100 text-blue-600 text-xs font-normal px-2.5 py-0.5 rounded-full">
                                                    Institute
                                                </span>
                                            </div>

                                            <div className="flex items-center text-sm">
                                                <span className="text-gray-700">
                                                    Posted on
                                                </span>
                                                <span className="text-black ml-2 font-semibold">
                                                    {(() => {
                                                        const date = new Date(
                                                            internship.createdAt
                                                        );
                                                        const day = date
                                                            .getDate()
                                                            .toString()
                                                            .padStart(2, '0');
                                                        const month =
                                                            date.toLocaleString(
                                                                'default',
                                                                {
                                                                    month: 'short',
                                                                }
                                                            );
                                                        const year = date
                                                            .getFullYear()
                                                            .toString()
                                                            .slice(-2);
                                                        return `${day}-${month}-${year}`;
                                                    })()}
                                                </span>
                                            </div>
                                            <div className="bg-slate-300 border-b-2 mt-4 w-11/12 mb-8"></div>
                                        </div>

                                        <div className="absolute bottom-4 right-10">
                                            <img
                                                src={arrow}
                                                alt="ArrowForBack"
                                                className="w-[17px] h-[17px] rotate-0"
                                            />
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                )}
            </div>
        </DashBoardLoader>
    );
};

export default InternshipCard;

const UserProfile = ({ onBack }) => {
    const [internships, setInternships] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userId, setUserId] = useState('');
    const [showContent, setShowContent] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const storedUserId = localStorage.getItem('uid');
        if (storedUserId) {
            setUserId(storedUserId);
        }
    }, []);

    useEffect(() => {
        const fetchInternshipData = async () => {
            if (!userId) return;
            setLoading(true);
            try {
                const data = await readRecord(
                    {},
                    `internships-by-creator/${userId}`
                );
                setInternships(data);
                console.log(data);

                setLoading(false);

                // Add a delay before showing content
                setTimeout(() => {
                    setShowContent(true);
                }, 500); // 1-second delay
            } catch (error) {
                setError('Failed to fetch internship data.');
                setLoading(false);
                toast.error('Failed to fetch internship data.');
            }
        };

        fetchInternshipData();
    }, [userId]);

    if (loading || !showContent) {
        return (
            <div className="p-6 flex flex-col items-center">
                {/* CSS for Spinner */}
                <style>
                    {`
                    .funny-spinner {
                        width: 40px;
                        height: 40px;
                        border: 4px solid #f3f3f3;
                        border-top: 4px solid #3498db;
                        border-radius: 50%;
                        animation: spin 1s linear infinite;
                        margin-bottom: 8px;
                    }
                    @keyframes spin {
                        0% { transform: rotate(0deg); }
                        100% { transform: rotate(360deg); }
                    }
                    `}
                </style>

                {/* Spinner Animation */}
                <div className="funny-spinner"></div>

                {/* Funny Loading Message */}
                <p className="text-gray-700 font-medium mt-4">
                    "Hold tight! Just convincing interns to jump in... ✈️"
                </p>
            </div>
        );
    }

    if (error) {
        return <p className="p-6 text-red-500">{error}</p>;
    }

    return (
        <div className="p-6 bg-white  rounded-md">
            <div className="flex gap-2 items-center mt-4 sm:mt-10 w-full mx-auto cursor-pointer mb-4">
                <img
                    src={arrow}
                    alt="ArrowForBack"
                    className="rotate-180 w-[17px] h-[17px]"
                    onClick={onBack}
                />
                <p
                    className="text-[16px] sm:text-[18px] font-[400] text-[#3061F4]"
                    onClick={onBack}
                >
                    Back
                </p>
            </div>

            {internships.length > 0 ? (
                internships.map((internship, index) => (
                    <div
                        key={index}
                        className="mb-8 p-4 bg-gray-50  rounded-lg border border-gray-200"
                    >
                        <h2 className="text-lg font-bold mb-2 truncate">
                            Internship Name:{' '}
                            <span className="whitespace-nowrap overflow-hidden text-ellipsis">
                                {internship.internship.title}
                            </span>
                        </h2>
                        <p className="text-gray-600 mb-4">
                            Internship Start Date:{' '}
                            {internship.internship.start_date}
                        </p>

                        {!internship.internship.status ? (
                            <p className="text-red-600 font-semibold mb-2">
                                This internship is not active right now.
                            </p>
                        ) : Array.isArray(internship.applicants) &&
                          internship.applicants.length > 0 ? (
                            <div className="space-y-4 sm:hidden">
                                {internship.applicants.map((applicant, idx) => (
                                    <div
                                        key={idx}
                                        className="border border-gray-200 rounded-lg p-4 shadow-md"
                                    >
                                        <div className="flex items-center space-x-4 mb-4">
                                            <div className="w-16 h-16 rounded-full overflow-hidden bg-gray-200">
                                                <img
                                                    src={
                                                        applicant.profilePicture
                                                            ?.url ||
                                                        'default-avatar.png'
                                                    }
                                                    alt="Profile"
                                                    className="w-full h-full object-cover"
                                                />
                                            </div>
                                            <div>
                                                <p className="font-bold text-lg truncate">
                                                    {applicant.name || 'N/A'}
                                                </p>
                                                <p className="text-gray-500">
                                                    {applicant.contact_number ||
                                                        'N/A'}
                                                </p>
                                            </div>
                                        </div>
                                        <p className="text-gray-600 mb-1 truncate">
                                            <strong>Email:</strong>{' '}
                                            {applicant.email || 'N/A'}
                                        </p>
                                        <p className="text-gray-600 truncate">
                                            <strong>Location:</strong>{' '}
                                            {applicant.city}, {applicant.state},{' '}
                                            {applicant.country}
                                        </p>

                                        {/* View Resume Button */}
                                        {applicant.resume && (
                                            <a
                                                href={applicant.resume}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-blue-500 font-bold  hover:text-blue-700 mt-2 block"
                                            >
                                                View Resume
                                            </a>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className="text-gray-500">
                                No student applied yet.
                            </p>
                        )}

                        {internship.internship.status &&
                            Array.isArray(internship.applicants) &&
                            internship.applicants.length > 0 && (
                                <div className="hidden sm:block">
                                    <table className="min-w-full bg-white border mt-4">
                                        <thead>
                                            <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                                                <th className="py-3 px-6 text-left">
                                                    Profile Picture
                                                </th>
                                                <th className="py-3 px-6 text-left">
                                                    Name
                                                </th>
                                                <th className="py-3 px-6 text-left">
                                                    Contact Number
                                                </th>
                                                <th className="py-3 px-6 text-left">
                                                    Email
                                                </th>
                                                <th className="py-3 px-6 text-left">
                                                    Location
                                                </th>
                                                <th className="py-3 px-6 text-left">
                                                    Resume
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-gray-600 text-sm font-light">
                                            {internship.applicants.map(
                                                (applicant, idx) => (
                                                    <tr
                                                        key={idx}
                                                        className="border-b border-gray-200 hover:bg-gray-100"
                                                    >
                                                        <td className="py-3 px-6 text-left">
                                                            <div className="w-16 h-16 rounded-full overflow-hidden bg-gray-200">
                                                                <img
                                                                    src={
                                                                        applicant
                                                                            .profilePicture
                                                                            ?.url ||
                                                                        'default-avatar.png'
                                                                    }
                                                                    alt="Profile"
                                                                    className="w-full h-full object-cover"
                                                                />
                                                            </div>
                                                        </td>
                                                        <td className="py-3 px-6 text-left">
                                                            {applicant.name ||
                                                                'N/A'}
                                                        </td>
                                                        <td className="py-3 px-6 text-left">
                                                            {applicant.contact_number ||
                                                                'N/A'}
                                                        </td>
                                                        <td className="py-3 px-6 text-left">
                                                            {applicant.email ||
                                                                'N/A'}
                                                        </td>
                                                        <td className="py-3 px-6 text-left">
                                                            {applicant.city},{' '}
                                                            {applicant.state},{' '}
                                                            {applicant.country}
                                                        </td>
                                                        <td className="py-3 px-6 text-left">
                                                            {/* View Resume Button */}
                                                            {applicant.resume && (
                                                                <a
                                                                    href={
                                                                        applicant.resume
                                                                    }
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    className="text-blue-500    font-bold  hover:text-blue-700"
                                                                >
                                                                    View Resume
                                                                </a>
                                                            )}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                    </div>
                ))
            ) : (
                <p>You haven't created any internships yet.</p>
            )}
        </div>
    );
};

// import React, { useEffect, useState, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';
// import ScrollY from '../../assets/Images/ScrollY.svg'; // Adjust path as needed
// import { DashBoardLoader } from '../Dashboard/NoDataChecker';
// import { readRecord } from '../../Services/backend/apiCalls';
// import toast from 'react-hot-toast';

// const InternshipTiDashboard = () => {
//     const [internships, setInternships] = useState([]);
//     const [userId, setUserId] = useState('');
//     const [loading, setLoading] = useState(true); // Add loading state
//     const navigate = useNavigate();
//     const scrollRef = useRef(null);

//     // Fetch user_id (uid) directly from local storage
//     useEffect(() => {
//         const storedUserId = localStorage.getItem('uid');
//         if (storedUserId) {
//             setUserId(storedUserId);
//         }
//     }, []);

//     useEffect(() => {
//         const fetchInternships = async () => {
//             if (!userId) return;

//             setLoading(true); // Set loading to true before fetching data
//             try {
//                 // Pass userId as a query parameter
//                 const data = await readRecord(
//                     { userId }, // Query parameters with userId
//                     'ti-internships' // Endpoint
//                 );

//                 if (data.success) {
//                     setInternships(data.data);
//                     console.log(data.data);
//                 } else {
//                     console.error('Data fetching unsuccessful:', data.message);
//                     toast.error('Failed to load internships data');
//                 }
//             } catch (error) {
//                 console.error('Error fetching internship data:', error);
//                 toast.error('Error fetching internship data');
//             } finally {
//                 setLoading(false); // Set loading to false after fetching
//             }
//         };

//         fetchInternships();
//     }, [userId]);

//     const handleCardClick = (internshipId) => {
//         navigate(`/internshipTi/${internshipId}`);
//     };

//     return (
//         <div className="p-6 bg-[#F2F4F5B2] relative w-full">
//             <div className="">
//                 <h1 className="text-[35px] font-[500] text-center">
//                     Your Internships
//                 </h1>
//                 <div className="w-[50px] mt-1 h-[3px] rounded-full m-auto bg-gradient-to-r from-[#04D5BD] to-[#267DF8]"></div>

//                 <div className="flex items-center justify-between space-x-5 h-[440px] mt-6">
//                     <img
//                         src={ScrollY}
//                         alt="ScrollY"
//                         className="cursor-pointer"
//                         onClick={() =>
//                             scrollRef.current &&
//                             (scrollRef.current.scrollLeft -= 1000)
//                         }
//                     />

//                     <div
//                         className="flex relative space-x-4 overflow-x-auto scrollBar scroll-smooth snap-x snap-mandatory "
//                         ref={scrollRef}
//                         style={{ width: 'calc(292px * 5 + 12px * 3)' }}
//                     >
//                         {loading ? (
//                             <div className="flex justify-center items-center w-full h-full">
//                                 <DashBoardLoader
//                                     loading={loading}
//                                     height="360px"
//                                 />
//                             </div>
//                         ) : internships.length === 0 ? (
//                             <p className="text-center ml-[45%]">
//                                 No internships available
//                             </p>
//                         ) : (
//                             internships.map((internship) => (
//                                 <div
//                                     key={internship.id}
//                                     className="bg-white p-4 rounded-lg sm:w-[292px] w-[230px] snap-start flex-shrink-0 flex flex-col justify-between cursor-pointer "
//                                     onClick={() =>
//                                         handleCardClick(internship.id)
//                                     }
//                                 >
//                                     <div className="">
//                                         <img
//                                             src={
//                                                 internship.thumbnail ||
//                                                 'https://via.placeholder.com/150'
//                                             }
//                                             alt={internship.title}
//                                             className="w-full h-40 object-contain rounded-lg mb-4"
//                                         />
//                                         <h2 className="text-xl font-semibold mb-2 truncate">
//                                             {internship.title}
//                                         </h2>
//                                         <p className="text-gray-600 mb-4 overflow-hidden text-ellipsis whitespace-nowrap truncate">
//                                             {internship.description}
//                                         </p>
//                                         <p className="text-sm text-gray-500 mb-2 truncate">
//                                             Company:{' '}
//                                             {typeof internship.company ===
//                                             'string'
//                                                 ? internship.company
//                                                 : internship.company?.name ||
//                                                   'N/A'}
//                                         </p>
//                                         <p className="text-sm text-gray-500">
//                                             <strong>
//                                                 Internship Type:{' '}
//                                                 {internship.stipend
//                                                     ? 'Paid'
//                                                     : 'Free'}
//                                             </strong>
//                                         </p>
//                                     </div>
//                                     <div className="flex items-end"></div>
//                                 </div>
//                             ))
//                         )}
//                     </div>
//                     <img
//                         src={ScrollY}
//                         alt="ScrollY"
//                         className="rotate-180 cursor-pointer"
//                         onClick={() =>
//                             scrollRef.current &&
//                             (scrollRef.current.scrollLeft += 1000)
//                         }
//                     />
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default InternshipTiDashboard;

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ScrollY from '../../assets/Images/ScrollY.svg';
import { DashBoardLoader } from '../Dashboard/NoDataChecker';
import { readRecord } from '../../Services/backend/apiCalls';
import toast from 'react-hot-toast';

const InternshipTiDashboard = () => {
    const [internships, setInternships] = useState([]);
    const [userId, setUserId] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const scrollRef = useRef(null);

    useEffect(() => {
        const storedUserId = localStorage.getItem('uid');
        if (storedUserId) {
            setUserId(storedUserId);
        }
    }, []);

    useEffect(() => {
        const fetchInternships = async () => {
            if (!userId) return;
            setLoading(true);
            try {
                const data = await readRecord({ userId }, 'ti-internships');
                console.log(data);

                if (data.success) {
                    setInternships(data.data);
                } else {
                    console.error('Data fetching unsuccessful:', data.message);
                    toast.error('Failed to load internships data');
                }
            } catch (error) {
                console.error('Error fetching internship data:', error);
                toast.error('Error fetching internship data');
            } finally {
                setLoading(false);
            }
        };

        fetchInternships();
    }, [userId]);

    const handleCardClick = (internshipId) => {
        navigate(`/internshipTi/${internshipId}`);
    };

    const InternshipCard = ({ internship }) => (
        <div
            key={internship.id}
            className="bg-white p-6 rounded-lg sm:w-[300px] w-[230px] flex-shrink-0 snap-start cursor-pointer flex flex-col justify-between"
            onClick={() => handleCardClick(internship.id)}
        >
            {/* Image */}
            <img
                src={internship.thumbnail || 'https://via.placeholder.com/150'}
                alt={internship.title}
                className="w-full h-40 object-contain mb-4 rounded-lg"
            />

            {/* Title */}
            <div>
                <h2 className="text-2xl font-bold text-black mb-3 truncate">
                    {internship.title}
                </h2>
                <div className="w-[50px] h-[3px] rounded-full bg-gradient-to-r from-[#04D5BD] to-[#267DF8] mb-3"></div>
            </div>

            {/* Company and Institute Tags */}
            <div className="flex items-center gap-2 mb-3">
                <span className="text-[15px] text-gray-700 font-semibold">
                    {typeof internship.company === 'string'
                        ? internship.company
                        : internship.company?.name || 'N/A'}
                </span>
                <span className="text-sm bg-[#ECFDF3] text-[#33bd87] px-3 py-0.5 rounded-full">
                    Institute
                </span>
            </div>

            {/* Posted Date */}
            <div className="flex items-center text-sm text-gray-700 mb-14">
                <span>Posted on</span>
                <span className="text-[#3B82F6] ml-2 font-semibold">
                    {(() => {
                        const date = new Date(internship.createdAt);
                        const day = date.getDate().toString().padStart(2, '0');
                        const month = date.toLocaleString('default', {
                            month: 'short',
                        });
                        const year = date.getFullYear().toString().slice(-2);
                        return `${day}-${month}-${year}`;
                    })()}
                </span>
            </div>

            {/* Apply Button */}
            <button
                onClick={() => handleCardClick(internship.id)}
                className="w-32   py-2 px-4 mt-2 rounded-lg text-sm font-medium bg-[#3B82F6] text-white hover:bg-blue-600"
            >
                View Details
            </button>
        </div>
    );

    return (
        <div className="py-8 bg-[#F2F4F5B2] relative w-full">
            <div className="w-[95%] mx-auto">
                {/* Header */}
                <div className="mb-8">
                    <h1 className="text-[35px] font-[500] text-center">
                        Your Internships
                    </h1>
                    <div className="w-[50px] mt-1 h-[3px] rounded-full m-auto bg-gradient-to-r from-[#04D5BD] to-[#267DF8]"></div>
                </div>

                <div className="flex items-center justify-between space-x-5">
                    <button
                        onClick={() =>
                            scrollRef.current &&
                            (scrollRef.current.scrollLeft -= 320)
                        }
                        className="p-2 hover:bg-gray-100 rounded-full transition-colors flex-shrink-0"
                    >
                        <img
                            src={ScrollY}
                            alt="Scroll Left"
                            className="w-6 h-6"
                        />
                    </button>

                    <div
                        ref={scrollRef}
                        className="flex gap-6 overflow-x-auto scrollBar scroll-smooth snap-x snap-mandatory px-4"
                        style={{
                            scrollbarWidth: 'none',
                            msOverflowStyle: 'none',
                        }}
                    >
                        {loading ? (
                            <div className="flex items-center justify-center w-full h-[440px]">
                                <DashBoardLoader
                                    loading={loading}
                                    height="360px"
                                />
                            </div>
                        ) : internships.length === 0 ? (
                            <p className="text-center ml-[45%]">
                                No internships available
                            </p>
                        ) : (
                            internships.map((internship) => (
                                <InternshipCard
                                    key={internship.id}
                                    internship={internship}
                                />
                            ))
                        )}
                    </div>

                    <button
                        onClick={() =>
                            scrollRef.current &&
                            (scrollRef.current.scrollLeft += 320)
                        }
                        className="p-2 hover:bg-gray-100 rounded-full transition-colors flex-shrink-0"
                    >
                        <img
                            src={ScrollY}
                            alt="Scroll Right"
                            className="w-6 h-6 rotate-180"
                        />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default InternshipTiDashboard;

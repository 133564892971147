// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { readRecord } from '../../Services/backend/apiCalls';
// import { DashBoardLoader } from '../Dashboard/NoDataChecker';
// import arrow from '../../assets/Images/RightArrow.svg';

// interface Internship {
//     application_deadline: string | number | Date;
//     id: number;
//     title: string;
//     company: string;
//     createdAt: string;
//     description: string;
//     thumbnail?: string;
//     internship_type?: string;
//     user_idSt: string[] | null;
//     start_date: string;
// }

// // Inline custom CSS for line clamping
// const lineClampStyle = `
//   .line-clamp-2 {
//       display: -webkit-box;
//       -webkit-line-clamp: 2;
//       -webkit-box-orient: vertical;
//       overflow: hidden;
//   }
// `;

// export const AllInternshipsPage = () => {
//     const [internships, setInternships] = useState<Internship[]>([]);
//     const [userId, setUserId] = useState<string | null>(null);
//     const [loading, setLoading] = useState<boolean>(true);
//     const navigate = useNavigate();

//     useEffect(() => {
//         const storedUserId = localStorage.getItem('uid');
//         if (storedUserId) {
//             setUserId(storedUserId);
//         }
//     }, []);

//     useEffect(() => {
//         const fetchInternships = async () => {
//             setLoading(true);
//             try {
//                 const data = await readRecord({}, 'internships');
//                 const currentDate = new Date();

//                 // Filter internships based on application_deadline
//                 const activeInternships = data.filter(
//                     (internship: Internship) => {
//                         return (
//                             new Date(internship.application_deadline) >
//                             currentDate
//                         );
//                     }
//                 );

//                 setInternships(activeInternships);
//                 console.log(activeInternships);
//             } catch (error) {
//                 console.error('Error fetching internships:', error);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchInternships();
//     }, []);
//     if (loading) {
//         return (
//             <div className="w-11/12 mx-auto py-6 min-h-screen flex flex-col items-center justify-center">
//                 <div className="w-16 h-16 border-4 border-t-blue-500 border-b-blue-500 rounded-full animate-spin"></div>
//                 <p className="mt-4 text-lg text-gray-600">
//                     Loading internships...
//                 </p>
//             </div>
//         );
//     }
//     return (
//         <>
//             {/* Inject custom CSS into the component */}

//             <style>{lineClampStyle}</style>

//             {
//                 <div className="w-11/12 mx-auto py-6  ">
//                     <h1 className="text-[35px] font-[500] text-center mt-6">
//                         All Internships
//                     </h1>
//                     <div className="flex gap-2 items-center  mt-5 w-full mx-auto cursor-pointer mb-4">
//                         <img
//                             src={arrow}
//                             alt="ArrowForBack"
//                             className="rotate-180 w-[17px] h-[17px]"
//                             onClick={() => navigate(-1)}
//                         />
//                         <p
//                             className="text-[16px] sm:text-[18px] font-[400] text-[#3061F4]"
//                             onClick={() => navigate(-1)}
//                         >
//                             Back
//                         </p>
//                     </div>
//                     <div className="mt-6">
//                         {internships.map((internship) => {
//                             const hasApplied: boolean = !!(
//                                 userId &&
//                                 Array.isArray(internship.user_idSt) &&
//                                 internship.user_idSt.includes(userId)
//                             );

//                             return (
//                                 <div
//                                     key={internship.id}
//                                     className="bg-white p-6 rounded-lg shadow-lg mb-6 flex flex-col sm:flex-row justify-between items-center"
//                                 >
//                                     <div className="flex flex-col sm:flex-row items-center sm:items-start sm:space-x-6 w-full">
//                                         <img
//                                             src={
//                                                 internship.thumbnail ||
//                                                 'https://via.placeholder.com/150?text=No+Image'
//                                             }
//                                             alt={internship.title}
//                                             className="w-full sm:w-24 h-40 sm:h-24 object-cover rounded-lg mb-4 sm:mb-0"
//                                         />
//                                         <div className="text-center sm:text-left w-full">
//                                             <h2 className="text-lg sm:text-[24px] font-[500] truncate">
//                                                 {internship.title}
//                                             </h2>
//                                             <p className="text-gray-600 text-sm sm:text-base truncate">
//                                                 {internship.company ||
//                                                     'No company info'}
//                                             </p>
//                                             {/* <p className="text-gray-600 text-sm sm:text-base truncate">
//                                                 {internship.internship_type ||
//                                                     'No internship type'}
//                                             </p> */}
//                                             <p className="text-gray-600 text-sm sm:text-base truncate">
//                                                 {internship.internship_type ===
//                                                 'Stipend'
//                                                     ? 'Paid (With Stipend)'
//                                                     : internship.internship_type ===
//                                                       'Paid'
//                                                     ? 'Paid (By Student)'
//                                                     : internship.internship_type ===
//                                                       'Free'
//                                                     ? 'Free'
//                                                     : 'No internship type'}
//                                             </p>
//                                             {/* <p className="text-gray-600 text-sm sm:text-base">
//                                                 Start Date{' '}
//                                                 {new Date(
//                                                     internship.createdAt
//                                                 ).toLocaleDateString()}
//                                             </p> */}
//                                             <p className="text-gray-500">
//                                                 Started Date on:{' '}
//                                                 {(() => {
//                                                     const date =
//                                                         internship.start_date;
//                                                     if (!date)
//                                                         return 'No date provided';

//                                                     const parsedDate = new Date(
//                                                         date
//                                                     );
//                                                     return parsedDate.getTime() >
//                                                         0
//                                                         ? parsedDate.toDateString()
//                                                         : 'No date provided';
//                                                 })()}
//                                             </p>
//                                             <p className="text-gray-600 text-sm sm:text-base line-clamp-2">
//                                                 {internship.description ||
//                                                     'No description available'}
//                                             </p>
//                                         </div>
//                                     </div>

//                                     <button
//                                         className={`mt-4 sm:mt-0 py-2 px-4 text-white font-normal rounded-md w-full sm:w-auto ${
//                                             hasApplied
//                                                 ? 'bg-gray-400 cursor-not-allowed'
//                                                 : ' bg-blue-600'
//                                         } flex items-center justify-center`}
//                                         onClick={() =>
//                                             !hasApplied &&
//                                             navigate(
//                                                 `/internship-info/${internship.id}`
//                                             )
//                                         }
//                                         disabled={hasApplied}
//                                     >
//                                         <span className="truncate">
//                                             {hasApplied
//                                                 ? 'Already Applied'
//                                                 : 'Apply'}
//                                         </span>
//                                     </button>
//                                 </div>
//                             );
//                         })}
//                     </div>
//                 </div>
//             }
//         </>
//     );
// };

// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { readRecord } from '../../Services/backend/apiCalls';
// import arrow from '../../assets/Images/RightArrow.svg';
// import Users from '../../assets/Images/Users.svg';
// import Mode from '../../assets/Images/mode.svg';
// import Session from '../../assets/Images/session.svg';
// import dateImg from '../../assets/Images/dateImg.svg';
// import rs from '../../assets/Images/rs.svg';
// import startDate from '../../assets/Images/startDate.svg';
// import certification from '../../assets/Images/certification.svg';

// interface Internship {
//     id: number;
//     user_id: string;
//     user_idSt: string[] | null;
//     company: string;
//     title: string;
//     description: string;
//     tags: string[];
//     days: string[];
//     duration: number;
//     duration_unit: string;
//     mode: string;
//     start_date: string;
//     start_time: string;
//     end_time: string;
//     internship_type: string;
//     stipend: number;
//     stipend_frequency: string;
//     is_active: boolean;
//     certificate: boolean;
//     location: string | null;
//     application_deadline: string;
//     requirements: string | null;
//     thumbnail: string | null;
//     resume_url: string | null;
//     createdAt: string;
//     updatedAt: string;
// }

// const DetailRow = ({
//     icon,
//     value,
//     isFirst = false,
// }: {
//     icon: string;
//     value: string;
//     isFirst?: boolean;
// }) => (
//     <div
//         className={`flex items-center gap-2 ${!isFirst ? 'border-l pl-3' : ''}`}
//     >
//         <img
//             src={icon}
//             alt="icon"
//             className={`${icon === Users ? 'w-[17px]' : 'w-[14px]'}`}
//         />
//         <p className="text-[#303030] text-[14px] font-[400] capitalize">
//             {value}
//         </p>
//     </div>
// );

// export const AllInternshipsPage = () => {
//     const [internships, setInternships] = useState<Internship[]>([]);
//     const [userId, setUserId] = useState<string | null>(null);
//     const [loading, setLoading] = useState<boolean>(true);
//     const navigate = useNavigate();

//     useEffect(() => {
//         const storedUserId = localStorage.getItem('uid');
//         if (storedUserId) {
//             setUserId(storedUserId);
//         }
//     }, []);

//     useEffect(() => {
//         const fetchInternships = async () => {
//             setLoading(true);
//             try {
//                 const data = await readRecord({}, 'internships');
//                 const currentDate = new Date();
//                 const activeInternships = data.filter(
//                     (internship: Internship) =>
//                         new Date(internship.application_deadline) >
//                             currentDate && internship.is_active
//                 );
//                 setInternships(activeInternships);
//             } catch (error) {
//                 console.error('Error fetching internships:', error);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchInternships();
//     }, []);

//     const formatDate = (dateString: string): string => {
//         if (!dateString) return 'Not specified';
//         try {
//             return new Date(dateString).toLocaleDateString('en-US', {
//                 year: 'numeric',
//                 month: 'short',
//                 day: 'numeric',
//             });
//         } catch {
//             return 'Invalid date';
//         }
//     };

//     if (loading) {
//         return (
//             <div className="min-h-screen bg-[#F5F5F5] flex items-center justify-center">
//                 <div className="loader ease-linear rounded-full border-4 border-t-4 border-[#3061F4] h-12 w-12"></div>
//             </div>
//         );
//     }

//     return (
//         <div className="min-h-screen bg-[#F5F5F5]  h-[1000px]">
//             <div className="container mx-auto px-4 py-8">
//                 <div
//                     className="flex gap-2 items-center mb-6 cursor-pointer"
//                     onClick={() => navigate(-1)}
//                 >
//                     <img
//                         src={arrow}
//                         alt="Back"
//                         className="rotate-180 w-[17px] h-[17px] object-contain"
//                     />
//                     <span className="text-[16px] font-[400] text-[#3061F4]">
//                         Back
//                     </span>
//                 </div>

//                 <div className="space-y-6">
//                     {internships.length === 0 ? (
//                         <div className="text-center text-[#898989] text-lg">
//                             No active internships available
//                         </div>
//                     ) : (
//                         internships.map((internship) => {
//                             const hasApplied =
//                                 userId &&
//                                 internship.user_idSt?.includes(userId);

//                             return (
//                                 <div
//                                     key={internship.id}
//                                     className="sm:flex bg-[#FBFBFB] rounded-lg  overflow-hidden"
//                                 >
//                                     {/* Left Section: Image */}
//                                     <div className="w-1/4 p-4">
//                                         <img
//                                             src={
//                                                 internship.thumbnail ||
//                                                 '/placeholder-image.jpg'
//                                             }
//                                             alt={internship.title}
//                                             className="w-full h-full object-cover rounded-lg"
//                                         />
//                                     </div>

//                                     {/* Right Section: Details */}
//                                     <div className="w-3/4 p-6">
//                                         <div className="flex justify-between items-start">
//                                             <div>
//                                                 <h2 className="text-[#085BEB]  text-3xl font-semibold mb-4 ">
//                                                     {internship.title}
//                                                 </h2>
//                                                 <p className="    text-black text-md mt-1  font-medium">
//                                                     {internship.company}
//                                                     <span> </span>
//                                                     <span className="bg-green-100 px-3 py-1 rounded-full text-sm text-[#07bea1] w-fit">
//                                                         Institute
//                                                     </span>
//                                                 </p>
//                                             </div>
//                                             <button
//                                                 onClick={() =>
//                                                     !hasApplied &&
//                                                     navigate(
//                                                         `/internship-info/${internship.id}`
//                                                     )
//                                                 }
//                                                 className={`px-4 py-2 rounded-md text-sm font-medium ${
//                                                     hasApplied
//                                                         ? 'bg-gray-400 cursor-not-allowed text-white'
//                                                         : 'bg-[#3061F4] text-white hover:bg-blue-600'
//                                                 }`}
//                                                 disabled={hasApplied || false}
//                                             >
//                                                 {hasApplied
//                                                     ? 'Already Applied'
//                                                     : 'Apply Now'}
//                                             </button>
//                                         </div>

//                                         <p className="text-gray-500 text-sm mt-6">
//                                             Posted at{' '}
//                                             <span className=" text-blue-600 font-semibold">
//                                                 {formatDate(
//                                                     internship.createdAt
//                                                 )}
//                                             </span>
//                                         </p>

//                                         {/* Tags */}
//                                         <div className="flex flex-wrap gap-2 mt-4">
//                                             {internship.tags.map(
//                                                 (tag, index) => (
//                                                     <span
//                                                         key={index}
//                                                         className="bg-[#F8F8F8] text-[#3061F4] text-xs px-3 py-1 rounded-full"
//                                                     >
//                                                         #{tag}
//                                                     </span>
//                                                 )
//                                             )}
//                                         </div>

//                                         {/* Description */}
//                                         <p className="text-gray-700 mt-4">
//                                             {internship.description ||
//                                                 'No description available'}
//                                         </p>

//                                         <div className="border-b-2 w-full mt-6"></div>

//                                         <div className="mt-8 space-y-4">
//                                             <div className="flex items-center gap-4">
//                                                 {/* <DetailRow
//                                                     icon={Users}
//                                                     value="1 Learner"
//                                                     isFirst={true}
//                                                 /> */}
//                                                 <DetailRow
//                                                     icon={Mode}
//                                                     value={internship.mode}
//                                                 />
//                                                 <DetailRow
//                                                     icon={Session}
//                                                     value={internship.days.join(
//                                                         ', '
//                                                     )}
//                                                 />
//                                                 <DetailRow
//                                                     icon={startDate}
//                                                     value={formatDate(
//                                                         internship.start_date
//                                                     )}
//                                                 />
//                                             </div>

//                                             <div className="flex items-center gap-4">
//                                                 <DetailRow
//                                                     icon={dateImg}
//                                                     value={`${internship.duration} ${internship.duration_unit}`}
//                                                     isFirst={true}
//                                                 />
//                                                 {internship.stipend > 0 && (
//                                                     <DetailRow
//                                                         icon={rs}
//                                                         value={`${internship.stipend.toLocaleString()} ${
//                                                             internship.stipend_frequency
//                                                         }`}
//                                                     />
//                                                 )}
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             );
//                         })
//                     )}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AllInternshipsPage;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { readRecord } from '../../Services/backend/apiCalls';
import arrow from '../../assets/Images/RightArrow.svg';
import Users from '../../assets/Images/Users.svg';
import Mode from '../../assets/Images/mode.svg';
import Session from '../../assets/Images/session.svg';
import dateImg from '../../assets/Images/dateImg.svg';
import rs from '../../assets/Images/rs.svg';
import startDate from '../../assets/Images/startDate.svg';
import certification from '../../assets/Images/certification.svg';

interface Internship {
    id: number;
    user_id: string;
    user_idSt: string[] | null;
    company: string;
    title: string;
    description: string;
    tags: string[];
    days: string[];
    duration: number;
    duration_unit: string;
    mode: string;
    start_date: string;
    start_time: string;
    end_time: string;
    internship_type: string;
    stipend: number;
    stipend_frequency: string;
    is_active: boolean;
    certificate: boolean;
    location: string | null;
    application_deadline: string;
    requirements: string | null;
    thumbnail: string | null;
    resume_url: string | null;
    createdAt: string;
    updatedAt: string;
}

const DetailRow = ({
    icon,
    value,
    isFirst = false,
}: {
    icon: string;
    value: string;
    isFirst?: boolean;
}) => (
    <div
        className={`flex items-center gap-2 ${
            !isFirst ? 'sm:border-l sm:pl-3' : ''
        }`}
    >
        <img
            src={icon}
            alt="icon"
            className={`${icon === Users ? 'w-[17px]' : 'w-[14px]'}`}
        />
        <p className="text-[#303030] text-[14px] font-[400] capitalize break-words">
            {value}
        </p>
    </div>
);

export const AllInternshipsPage = () => {
    const [internships, setInternships] = useState<Internship[]>([]);
    const [userId, setUserId] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    useEffect(() => {
        const storedUserId = localStorage.getItem('uid');
        if (storedUserId) {
            setUserId(storedUserId);
        }
    }, []);

    useEffect(() => {
        const fetchInternships = async () => {
            setLoading(true);
            try {
                const data = await readRecord({}, 'internships');
                const currentDate = new Date();
                const activeInternships = data.filter(
                    (internship: Internship) =>
                        new Date(internship.application_deadline) >
                            currentDate && internship.is_active
                );
                setInternships(activeInternships);
            } catch (error) {
                console.error('Error fetching internships:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchInternships();
    }, []);

    const formatDate = (dateString: string): string => {
        if (!dateString) return 'Not specified';
        try {
            return new Date(dateString).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
            });
        } catch {
            return 'Invalid date';
        }
    };

    if (loading) {
        return (
            <div className="min-h-screen bg-[#F5F5F5] flex items-center justify-center">
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-[#3061F4] h-12 w-12 animate-spin"></div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-[#F5F5F5]">
            <div className="container mx-auto px-4 py-4 sm:py-8">
                <div
                    className="flex gap-2 items-center mb-4 sm:mb-6 cursor-pointer"
                    onClick={() => navigate(-1)}
                >
                    <img
                        src={arrow}
                        alt="Back"
                        className="rotate-180 w-[17px] h-[17px] object-contain"
                    />
                    <span className="text-[16px] font-[400] text-[#3061F4]">
                        Back
                    </span>
                </div>

                <div className="space-y-4 sm:space-y-6">
                    {internships.length === 0 ? (
                        <div className="text-center text-[#898989] text-lg">
                            No active internships available
                        </div>
                    ) : (
                        internships.map((internship) => {
                            const hasApplied =
                                userId &&
                                internship.user_idSt?.includes(userId);

                            return (
                                <div
                                    key={internship.id}
                                    className="flex flex-col sm:flex-row bg-[#FBFBFB] rounded-lg overflow-hidden shadow-sm"
                                >
                                    {/* Image Section */}
                                    <div className="w-full sm:w-1/4 p-4 h-[200px] sm:h-auto">
                                        <img
                                            src={
                                                internship.thumbnail ||
                                                '/placeholder-image.jpg'
                                            }
                                            alt={internship.title}
                                            className="w-full h-full object-cover rounded-lg"
                                        />
                                    </div>

                                    {/* Details Section */}
                                    <div className="w-full sm:w-3/4 p-4 sm:p-6 flex flex-col">
                                        <div className="flex-grow">
                                            <div className="sm:flex sm:justify-between sm:items-start">
                                                <div>
                                                    <h2 className="text-[#085BEB] text-2xl sm:text-3xl font-semibold mb-2 sm:mb-4 line-clamp-2">
                                                        {internship.title}
                                                    </h2>
                                                    <div className="flex flex-wrap gap-2 items-center">
                                                        <p className="text-black text-md font-medium">
                                                            {internship.company}
                                                        </p>
                                                        <span className="bg-green-100 px-3 py-1 rounded-full text-sm text-[#07bea1]">
                                                            Institute
                                                        </span>
                                                    </div>
                                                </div>
                                                <button
                                                    onClick={() =>
                                                        !hasApplied &&
                                                        navigate(
                                                            `/internship-info/${internship.id}`
                                                        )
                                                    }
                                                    className={`hidden sm:block px-6 py-2 rounded-md text-sm font-medium transition-colors ${
                                                        hasApplied
                                                            ? 'bg-gray-400 cursor-not-allowed text-white'
                                                            : 'bg-[#3061F4] text-white hover:bg-blue-600'
                                                    }`}
                                                    disabled={
                                                        hasApplied || false
                                                    }
                                                >
                                                    {hasApplied
                                                        ? 'Already Applied'
                                                        : 'Apply Now'}
                                                </button>
                                            </div>

                                            <p className="text-gray-500 text-sm mt-4 sm:mt-6">
                                                Posted at{' '}
                                                <span className="text-blue-600 font-semibold">
                                                    {formatDate(
                                                        internship.createdAt
                                                    )}
                                                </span>
                                            </p>

                                            <div className="flex flex-wrap gap-2 mt-4">
                                                {internship.tags.map(
                                                    (tag, index) => (
                                                        <span
                                                            key={index}
                                                            className="bg-[#F8F8F8] text-[#3061F4] text-xs px-3 py-1 rounded-full"
                                                        >
                                                            #{tag}
                                                        </span>
                                                    )
                                                )}
                                            </div>

                                            <p className="text-gray-700 mt-4 line-clamp-3 text-sm sm:text-base">
                                                {internship.description ||
                                                    'No description available'}
                                            </p>

                                            <div className="border-b-2 border-gray-100 w-full mt-6"></div>

                                            <div className="mt-6 sm:mt-8 space-y-4">
                                                <div className="flex flex-wrap gap-4">
                                                    <DetailRow
                                                        icon={Mode}
                                                        value={internship.mode}
                                                        isFirst={true}
                                                    />
                                                    <DetailRow
                                                        icon={Session}
                                                        value={internship.days.join(
                                                            ', '
                                                        )}
                                                    />
                                                    <DetailRow
                                                        icon={startDate}
                                                        value={formatDate(
                                                            internship.start_date
                                                        )}
                                                    />
                                                </div>

                                                <div className="flex flex-wrap gap-4">
                                                    <DetailRow
                                                        icon={dateImg}
                                                        value={`${internship.duration} ${internship.duration_unit}`}
                                                        isFirst={true}
                                                    />
                                                    {internship.stipend > 0 && (
                                                        <DetailRow
                                                            icon={rs}
                                                            value={`${internship.stipend.toLocaleString()} ${
                                                                internship.stipend_frequency
                                                            }`}
                                                        />
                                                    )}
                                                    {internship.certificate && (
                                                        <DetailRow
                                                            icon={certification}
                                                            value="Certificate Available"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        {/* Mobile Apply Button - At Bottom */}
                                        <button
                                            onClick={() =>
                                                !hasApplied &&
                                                navigate(
                                                    `/internship-info/${internship.id}`
                                                )
                                            }
                                            className={`sm:hidden w-full mt-6 px-6 py-2 rounded-md text-sm font-medium transition-colors ${
                                                hasApplied
                                                    ? 'bg-gray-400 cursor-not-allowed text-white'
                                                    : 'bg-[#3061F4] text-white hover:bg-blue-600'
                                            }`}
                                            disabled={hasApplied || false}
                                        >
                                            {hasApplied
                                                ? 'Already Applied'
                                                : 'Apply Now'}
                                        </button>
                                    </div>
                                </div>
                            );
                        })
                    )}
                </div>
            </div>
        </div>
    );
};

export default AllInternshipsPage;

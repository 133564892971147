// import React, { useEffect, useState, useRef } from 'react';
// import ScrollY from '../../assets/Images/ScrollY.svg';
// import circleBlue from '../../assets/Images/circleBlue.svg';
// import { useNavigate } from 'react-router-dom';
// import { readRecord } from '../../Services/backend/apiCalls';
// import { DashBoardLoader } from '../Dashboard/NoDataChecker';

// interface Internship {
//     id: number;
//     title: string;
//     description: string;
//     thumbnail?: string;
//     company?: string;
//     trainees_count: number;
//     stipend?: number | null;
//     is_active: boolean;
//     application_deadline: string;
//     user_idST: string[]; // Add this line
//     internship_type: string;
// }

// // Custom CSS for line-clamping
// const lineClampStyle = `
//   .line-clamp-2 {
//       display: -webkit-box;
//       -webkit-line-clamp: 2;
//       -webkit-box-orient: vertical;
//       overflow: hidden;
//   }
// `;

// export const InternshipSuggestions = () => {
//     const [internships, setInternships] = useState<Internship[]>([]);
//     const [loading, setLoading] = useState<boolean>(true);
//     const scrollRef = useRef<HTMLDivElement>(null);
//     const navigate = useNavigate();
//     const userIdSt = localStorage.getItem('uid');

//     console.log(userIdSt);

// useEffect(() => {
//     const fetchInternships = async () => {
//         setLoading(true);
//         try {
//             const data = await readRecord({}, 'internships');
//             console.log(data);

//             const activeInternships = data.filter(
//                 (internship: Internship) => internship.is_active
//             );
//             setInternships(activeInternships);
//         } catch (error) {
//             console.error('Error fetching internships:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     fetchInternships();
// }, []);

//     useEffect(() => {
//         const fetchInternships = async () => {
//             setLoading(true);
//             try {
//                 const data = await readRecord({}, 'internships');
//                 console.log(data);

//                 const activeInternships = data.filter(
//                     (internship: Internship) =>
//                         internship.is_active &&
//                         new Date(internship.application_deadline) > new Date() // Filter by active status and application deadline
//                 );
//                 setInternships(activeInternships);
//             } catch (error) {
//                 console.error('Error fetching internships:', error);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchInternships();
//     }, []);

//     const internshipMap = (
//         <div
//             className="flex relative space-x-4 overflow-x-auto scrollBar scroll-smooth snap-x snap-mandatory w-full"
//             ref={scrollRef}
//         >
//             {internships.map((internship) => (
//                 <div
//                     key={internship.id}
//                     className="bg-white p-4 rounded-lg sm:w-[292px] w-[240px] snap-start flex-shrink-0 flex flex-col justify-between"
//                 >
//                     <div>
//                         <img
//                             src={
//                                 internship.thumbnail ||
//                                 'https://via.placeholder.com/150'
//                             }
//                             alt={internship.title}
//                             className="w-full h-40 object-contain rounded-lg mb-4 cursor-pointer"
//                             onClick={() =>
//                                 navigate(`/internship-info/${internship.id}`)
//                             }
//                         />
//                         <h2 className="text-xl font-semibold mb-2 truncate">
//                             {internship.title}
//                         </h2>
//                         <p className="text-gray-600 mb-4 line-clamp-2">
//                             Description: {internship.description}
//                         </p>
//                         <p className="text-sm text-gray-500 mb-2 truncate">
//                             Company: {internship.company || 'N/A'}
//                         </p>
//                         <p className="  text-xs text-gray-500 mb-2 truncate">
//                             Application Deadline :{' '}
//                             {new Date(
//                                 internship.application_deadline
//                             ).toLocaleDateString('en-US', {
//                                 year: 'numeric',
//                                 month: 'short',
//                                 day: 'numeric',
//                             })}{' '}
//                         </p>

//                         {/* <p className="text-sm text-gray-500">
//                             <strong>
//                                 Internship Type:{' '}
//                                 {internship.stipend ? 'Paid' : 'Free'}
//                             </strong>
//                         </p> */}
//                         <p className="text-sm text-gray-500">
//                             <strong>
//                                 Internship Type:{' '}
//                                 {(() => {
//                                     switch (internship.internship_type) {
//                                         case 'Stipend':
//                                             return 'Paid (With Stipend)';
//                                         case 'Paid':
//                                             return 'Paid (By Student)';
//                                         default:
//                                             return 'Free';
//                                     }
//                                 })()}
//                             </strong>
//                         </p>
//                     </div>
//                     <div className="flex items-end"></div>
//                 </div>
//             ))}
//         </div>
//     );

//     return (
//         <>
//             {/* Inject custom CSS for line-clamp-2 */}
//             <style>{lineClampStyle}</style>
//             <div className="py-6 bg-[#F2F4F5B2] relative w-full">
//                 <img
//                     src={circleBlue}
//                     alt="circleBlue"
//                     className="absolute rotate-180 top-0 right-0 sm:z-0 -z-20"
//                 />
//                 <div className="w-[95%] mx-auto">
//                     <div>
//                         <h1 className="text-[35px] font-[500] text-center ">
//                             Internships Just For You
//                         </h1>
//                         <div className="w-[50px] mt-1 h-[3px] rounded-full m-auto bg-gradient-to-r from-[#04D5BD] to-[#267DF8]"></div>
//                     </div>

//                     {internships.length > 4 && (
//                         <div className="flex justify-end">
//                             <p
//                                 onClick={() => navigate('/all-intership')}
//                                 className="py-3 pr-8 cursor-pointer text-[14px] font-[500] text-[#5F5F5F] hover:text-[#267DF8]"
//                             >
//                                 See All
//                             </p>
//                         </div>
//                     )}
//                     {loading ? (
//                         <div className="flex items-center justify-center h-[440px]">
//                             <DashBoardLoader loading={loading} height="360px" />
//                         </div>
//                     ) : (
//                         <div className="flex items-center justify-between space-x-5 h-[440px]">
//                             <img
//                                 src={ScrollY}
//                                 alt="ScrollY"
//                                 className="cursor-pointer"
//                                 onClick={() =>
//                                     scrollRef.current &&
//                                     (scrollRef.current.scrollLeft -= 1000)
//                                 }
//                             />
//                             {internshipMap}
//                             <img
//                                 src={ScrollY}
//                                 alt="ScrollY"
//                                 className="rotate-180 cursor-pointer"
//                                 onClick={() =>
//                                     scrollRef.current &&
//                                     (scrollRef.current.scrollLeft += 1000)
//                                 }
//                             />
//                         </div>
//                     )}
//                 </div>
//             </div>
//         </>
//     );
// };

import React, { useEffect, useState, useRef } from 'react';
import ScrollY from '../../assets/Images/ScrollY.svg';
import circleBlue from '../../assets/Images/circleBlue.svg';
import { useNavigate } from 'react-router-dom';
import { readRecord } from '../../Services/backend/apiCalls';
import { DashBoardLoader } from '../Dashboard/NoDataChecker';

interface Internship {
    id: number;
    title: string;
    thumbnail?: string;
    company?: string;
    createdAt: string;
    user_idSt: string[] | null; // Using the same field name as working component
    description?: string;
    internship_type?: string;
    start_date?: string;
    is_active: boolean; // Add this field
}

export const InternshipSuggestions = () => {
    const [internships, setInternships] = useState<Internship[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [userId, setUserId] = useState<string | null>(null);
    const scrollRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const activeInternships = internships.filter(
        (internship) => internship.is_active
    );

    // Get userId when component mounts
    useEffect(() => {
        const storedUserId = localStorage.getItem('uid');
        if (storedUserId) {
            setUserId(storedUserId);
        }
    }, []);

    useEffect(() => {
        const fetchInternships = async () => {
            setLoading(true);
            try {
                const data = await readRecord({}, 'internships');
                setInternships(data);
                console.log('Fetched internships:', data);
            } catch (error) {
                console.error('Error fetching internships:', error);
            } finally {
                setLoading(false);
            }
        };

        if (userId) {
            fetchInternships();
        }
    }, [userId]);

    const InternshipCard = ({ internship }: { internship: Internship }) => {
        const hasApplied =
            Array.isArray(internship.user_idSt) &&
            userId &&
            internship.user_idSt.includes(userId);

        return (
            <div className="bg-white p-6 rounded-lg w-[300px] flex-shrink-0 snap-start">
                {/* Image */}
                <img
                    src={
                        internship.thumbnail ||
                        'https://via.placeholder.com/150'
                    }
                    alt={internship.title}
                    className="w-full h-44 object-contain mb-4"
                />

                {/* Title */}
                <div>
                    <h2 className="text-2xl font-bold text-black mb-3 truncate">
                        {internship.title}
                    </h2>
                    <div className="w-[50px]  h-[3px] rounded-full m-auto bg-gradient-to-r from-[#04D5BD] to-[#267DF8]     mr-52 mb-3 "></div>
                </div>

                {/* Company and Institute tags */}
                <div className="flex items-center gap-2 mb-3">
                    <span className="text-[15px] text-gray-700 font-semibold">
                        {internship.company || 'Bsoft education'}
                    </span>
                    <span className="text-sm bg-[#ECFDF3] text-[#33bd87] px-3 py-0.5 rounded-full">
                        Institute
                    </span>
                </div>

                {/* Posted Date */}
                <div className="flex items-center mb-14 text-sm">
                    <span className="text-gray-700">Posted on</span>
                    <span className="text-[#3B82F6] ml-2 font-semibold">
                        {(() => {
                            const date = new Date(internship.createdAt);
                            const day = date
                                .getDate()
                                .toString()
                                .padStart(2, '0');
                            const month = date.toLocaleString('default', {
                                month: 'short',
                            });
                            const year = date
                                .getFullYear()
                                .toString()
                                .slice(-2);
                            return `${day}-${month}-${year}`;
                        })()}
                    </span>
                </div>

                {/* Button */}
                <button
                    onClick={() =>
                        !hasApplied &&
                        navigate(`/internship-info/${internship.id}`)
                    }
                    disabled={hasApplied || false}
                    className={` py-2 px-6 rounded-lg text-sm ${
                        hasApplied
                            ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                            : 'bg-[#3B82F6] text-white hover:bg-blue-600'
                    }`}
                >
                    {hasApplied ? 'Applied' : 'Apply Now'}
                </button>
            </div>
        );
    };

    return (
        <div className="py-8 bg-[#F2F4F5B2] relative w-full">
            <img
                src={circleBlue}
                alt="circleBlue"
                className="absolute rotate-180 top-0 right-0 sm:z-0 -z-20"
            />
            <div className="w-[95%] mx-auto">
                {/* Header */}
                <div className="mb-8">
                    <h1 className="text-[35px] font-[500] text-center">
                        Internships Just For You
                    </h1>
                    <div className="w-[50px] mt-1 h-[3px] rounded-full m-auto bg-gradient-to-r from-[#04D5BD] to-[#267DF8]"></div>
                </div>

                {/* See All Link */}
                {internships.length > 4 && (
                    <div className="flex justify-end mb-4">
                        <button
                            onClick={() => navigate('/all-intership')}
                            className="text-sm font-medium text-gray-600 hover:text-blue-600 transition-colors"
                        >
                            See All
                        </button>
                    </div>
                )}

                {/* Content */}
                {loading ? (
                    <div className="flex items-center justify-center h-[440px]">
                        <DashBoardLoader loading={loading} height="360px" />
                    </div>
                ) : (
                    <div className="flex items-center justify-between space-x-5">
                        <button
                            onClick={() =>
                                scrollRef.current &&
                                (scrollRef.current.scrollLeft -= 320)
                            }
                            className="p-2 hover:bg-gray-100 rounded-full transition-colors flex-shrink-0"
                        >
                            <img
                                src={ScrollY}
                                alt="Scroll Left"
                                className="w-6 h-6"
                            />
                        </button>

                        <div
                            ref={scrollRef}
                            className="flex gap-6 overflow-x-auto scrollBar scroll-smooth snap-x snap-mandatory px-4"
                            style={{
                                scrollbarWidth: 'none',
                                msOverflowStyle: 'none',
                            }}
                        >
                            {activeInternships.map((internship) => (
                                <InternshipCard
                                    key={internship.id}
                                    internship={internship}
                                />
                            ))}
                        </div>

                        <button
                            onClick={() =>
                                scrollRef.current &&
                                (scrollRef.current.scrollLeft += 320)
                            }
                            className="p-2 hover:bg-gray-100 rounded-full transition-colors flex-shrink-0"
                        >
                            <img
                                src={ScrollY}
                                alt="Scroll Right"
                                className="w-6 h-6 rotate-180"
                            />
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

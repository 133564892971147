import React, { useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import closeBtn from '../../assets/Images/closeBtn.svg';
import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';

interface tags {
    tags: string[];
    setFieldValue: any;
    values: any;
    error?: any;
    onBlur?: any;
    formik?: any;
}

// export const Tags = ({ tags, setFieldValue, values, onBlur, formik }: tags) => {
//     const [tagErr, setTagErr] = useState(false);
//     const [input, setInput] = useState('');
//     const inputRef: any = useRef(null);
//     const errorValue = formik.touched.tags && formik.errors.tags;

//     // const setTags = (tag: string) => {
//     //     if (tag==="") {
//     //         camelCaseConvertForErrorMessage('Please Enter Tag');
//     //     } else {
//     //         if (values.tags.length >= 4) {
//     //             return toast.error(
//     //                 camelCaseConvertForErrorMessage('Only Four Tags Allowed')
//     //             );
//     //         }
//     //         if (values.tags.includes(tag.toLowerCase())) {
//     //             return toast.error(
//     //                 camelCaseConvertForErrorMessage('Tag Already Entered')
//     //             );
//     //         }
//     //         setFieldValue('tags', [...values.tags, tag.toLowerCase()]);
//     //     }
//     // };
//     // i updated this
//     const setTags = (tag: string) => {
//         // Trim the input to remove leading and trailing spaces
//         const trimmedTag = tag.trim();

//         // Check if the trimmed tag is empty (contains only spaces)
//         if (trimmedTag === '') {
//             camelCaseConvertForErrorMessage('Please Enter Tag');
//         } else {
//             if (values.tags.length >= 4) {
//                 return toast.error(
//                     camelCaseConvertForErrorMessage('Only Four Tags Allowed')
//                 );
//             }
//             if (values.tags.includes(trimmedTag.toLowerCase())) {
//                 return toast.error(
//                     camelCaseConvertForErrorMessage('Tag Already Entered')
//                 );
//             }
//             setFieldValue('tags', [...values.tags, trimmedTag.toLowerCase()]);
//         }
//     };

//     const removeTags = (tag: string) => {
//         const newTagsArray: string[] = values.tags.filter(
//             (tag_: string) => tag_ !== tag
//         );
//         setFieldValue('tags', newTagsArray);
//     };

//     return (
//         <div className="mb-8 cursor-text relative">
//             <p className="text-[15px] font-[500]">
//                 Relevant Tags<span className="ml-0.5 text-red-600">*</span>
//             </p>
//             <div
//                 onClick={() => inputRef.current.focus()}
//                 className="border-[0.7px] border-black bg-white w-full h-[55px] px-4 mt-3 rounded-[8px] flex space-x-5 items-center overflow-x-auto overflow-y-hidden"
//             >
//                 {tags.map((tag: string, index: number) => {
//                     // console.log(typeof tag, 'tag'),
//                     if (tag !== '') {
//                         return (
//                             <div
//                                 key={index}
//                                 className="flex items-center space-x-3 bg-[#F8F8F8] px-4 py-2 rounded-[17px]"
//                             >
//                                 <p className="text-[14px]">#{tag}</p>
//                                 <img
//                                     className="h-[11px] w-[11px] cursor-pointer"
//                                     src={closeBtn}
//                                     alt="closeBtn"
//                                     onClick={() => removeTags(tag)}
//                                 />
//                             </div>
//                         );
//                     }
//                 })}
//                 <input
//                     // title="taginputbox"
//                     name="tags"
//                     type="text"
//                     ref={inputRef}
//                     value={input}
//                     onChange={(e: any) => {
//                         // if (e.target.value) {
//                         //     setTagErr(false);
//                         // } else {
//                         //     setTagErr(true);
//                         // }
//                         setInput(e.target.value);
//                     }}
//                     placeholder="Press Enter to add tagssss"
//                     onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
//                         if (['Enter'].includes(e.key)) {
//                             setTags(input);
//                             setInput('');
//                         } else if (e.key === 'Backspace' && !input.length) {
//                             removeTags(tags[tags.length - 1]);
//                         }
//                         // console.log(e.key);
//                     }}
//                     className="h-full outline-none"
//                     // onBlur={(event:React.FocusEvent<HTMLInputElement>) => {
//                     //     if (input.length) {
//                     //         setTagErr(false);
//                     //         setTags(input);
//                     //         setInput('');
//                     //     } else {
//                     //         setTagErr(true);
//                     //     }
//                     //     onBlur(event);
//                     // }}
//                     onBlur={formik.handleBlur}
//                     // errors={errors.tags}
//                     // isTouched={touched.tags}

//                 />
//             </div>
//             {errorValue ? (
//                 <p
//                     style={{
//                         float: 'right',
//                         opacity: 1,
//                         color: 'rgb(239 68 68)',
//                         fontSize: '12px',
//                         marginTop: '.5%',
//                     }}
//                 >
//                     {formik.errors.tags}
//                 </p>
//             ) : // <p className="text-red-500 text-[12px] absolute right-4 bottom-[-30px]">
//             //     {errors}
//             // </p>
//             null}
//         </div>
//     );
// };

// export const Tags = ({ tags, setFieldValue, values, onBlur, formik }: tags) => {
//     const [input, setInput] = useState('');
//     const inputRef: any = useRef(null);
//     const errorValue = formik.touched.tags && formik.errors.tags;

//     const setTags = (tag: string) => {
//         const trimmedTag = tag.trim();

//         if (trimmedTag === '') {
//             camelCaseConvertForErrorMessage('Please Enter Tag');
//         } else {
//             if (values.tags.length >= 4) {
//                 return toast.error(
//                     camelCaseConvertForErrorMessage('Only Four Tags Allowed')
//                 );
//             }
//             if (values.tags.includes(trimmedTag.toLowerCase())) {
//                 return toast.error(
//                     camelCaseConvertForErrorMessage('Tag Already Entered')
//                 );
//             }
//             setFieldValue('tags', [...values.tags, trimmedTag.toLowerCase()]);
//         }
//     };

//     const removeTags = (tag: string) => {
//         const newTagsArray: string[] = values.tags.filter(
//             (tag_: string) => tag_ !== tag
//         );
//         setFieldValue('tags', newTagsArray);
//     };

//     const handleAddTag = () => {
//         if (input) {
//             setTags(input);
//             setInput('');
//         }
//     };

//     return (
//         <div className="mb-8 relative">
//             <p className="text-[15px] font-[500] mb-2">
//                 Relevant Tags<span className="ml-0.5 text-red-600">*</span>
//             </p>
//             <div className="flex flex-col gap-2">
//                 <div className="flex gap-2">
//                     <div className="flex-1 border border-gray-200 rounded-lg bg-white px-4 py-2.5">
//                         <input
//                             name="tags"
//                             type="text"
//                             ref={inputRef}
//                             value={input}
//                             onChange={(e: any) => setInput(e.target.value)}
//                             placeholder="Add tags"
//                             onKeyDown={(
//                                 e: React.KeyboardEvent<HTMLInputElement>
//                             ) => {
//                                 if (['Enter'].includes(e.key)) {
//                                     handleAddTag();
//                                 } else if (
//                                     e.key === 'Backspace' &&
//                                     !input.length
//                                 ) {
//                                     removeTags(tags[tags.length - 1]);
//                                 }
//                             }}
//                             className="w-full outline-none"
//                             onBlur={formik.handleBlur}
//                         />
//                     </div>
//                     <button
//                         onClick={handleAddTag}
//                         type="button"
//                         className="md:hidden bg-blue-500 hover:bg-blue-600 text-white px-6 py-2.5 rounded-lg font-medium transition-colors"
//                     >
//                         Add
//                     </button>
//                 </div>

//                 {tags.length > 0 && (
//                     <div className="flex flex-wrap gap-2">
//                         {tags.map((tag: string, index: number) => {
//                             if (tag !== '') {
//                                 return (
//                                     <div
//                                         key={index}
//                                         className="flex items-center gap-2 bg-gray-100 px-3 py-1.5 rounded-lg"
//                                     >
//                                         <span className="text-[14px]">
//                                             #{tag}
//                                         </span>
//                                         <img
//                                             className="h-[11px] w-[11px] cursor-pointer"
//                                             src={closeBtn}
//                                             alt="closeBtn"
//                                             onClick={() => removeTags(tag)}
//                                         />
//                                     </div>
//                                 );
//                             }
//                         })}
//                     </div>
//                 )}
//             </div>
//             {errorValue && (
//                 <p className="text-red-500 text-xs mt-1">
//                     {formik.errors.tags}
//                 </p>
//             )}
//         </div>
//     );
// };

export const Tags = ({ tags, setFieldValue, values, onBlur, formik }: tags) => {
    const [input, setInput] = useState('');
    const inputRef: any = useRef(null);
    const errorValue = formik.touched.tags && formik.errors.tags;

    const setTags = (tag: string) => {
        const trimmedTag = tag.trim();

        if (trimmedTag === '') {
            camelCaseConvertForErrorMessage('Please Enter Tag');
        } else {
            if (values.tags.length >= 4) {
                return toast.error(
                    camelCaseConvertForErrorMessage('Only Four Tags Allowed')
                );
            }
            if (values.tags.includes(trimmedTag.toLowerCase())) {
                return toast.error(
                    camelCaseConvertForErrorMessage('Tag Already Entered')
                );
            }
            setFieldValue('tags', [...values.tags, trimmedTag.toLowerCase()]);
        }
    };

    const removeTags = (tag: string) => {
        const newTagsArray: string[] = values.tags.filter(
            (tag_: string) => tag_ !== tag
        );
        setFieldValue('tags', newTagsArray);
    };

    const handleAddTag = () => {
        if (input) {
            setTags(input);
            setInput('');
        }
    };

    return (
        <div className="mb-8 relative">
            <p className="text-[15px] font-[500] mb-2">
                Relevant Tags<span className="ml-0.5 text-red-600">*</span>
            </p>
            <div className="border border-gray-200 rounded-lg bg-white px-4 py-2.5 flex items-center gap-2">
                <div className="flex flex-wrap items-center gap-2 flex-1">
                    {tags.map((tag: string, index: number) => {
                        if (tag !== '') {
                            return (
                                <div
                                    key={index}
                                    className="flex items-center gap-2 bg-gray-100 px-3 py-1.5 rounded-lg"
                                >
                                    <span className="text-[14px]">#{tag}</span>
                                    <img
                                        className="h-[11px] w-[11px] cursor-pointer"
                                        src={closeBtn}
                                        alt="closeBtn"
                                        onClick={() => removeTags(tag)}
                                    />
                                </div>
                            );
                        }
                    })}
                    <input
                        name="tags"
                        type="text"
                        ref={inputRef}
                        value={input}
                        onChange={(e: any) => setInput(e.target.value)}
                        placeholder="Add tags"
                        onKeyDown={(
                            e: React.KeyboardEvent<HTMLInputElement>
                        ) => {
                            if (['Enter'].includes(e.key)) {
                                handleAddTag();
                            } else if (e.key === 'Backspace' && !input.length) {
                                removeTags(tags[tags.length - 1]);
                            }
                        }}
                        className="outline-none min-w-[120px] flex-1"
                        onBlur={formik.handleBlur}
                    />
                </div>
                <button
                    onClick={handleAddTag}
                    type="button"
                    className="md:hidden bg-blue-500 hover:bg-blue-600 text-white px-6 py-1.5 rounded-lg font-medium transition-colors whitespace-nowrap"
                >
                    Add
                </button>
            </div>
            {errorValue && (
                <p className="text-red-500 text-xs mt-1">
                    {formik.errors.tags}
                </p>
            )}
        </div>
    );
};

export const DisplayReadOnlyTags = ({ tags }: { tags: string[] }) => {
    return (
        <div className="flex flex-wrap my-2">
            {tags.length > 0 &&
                tags.map((tag: string, index: number) => (
                    <p
                        className="bg-[#F8F8F8] mr-2 text-[#3061F4] font-[400] text-[12px] px-4 py-1 rounded-[18px]"
                        key={index}
                    >
                        #{tag}
                    </p>
                ))}
        </div>
    );
};

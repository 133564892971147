import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../..';
import { useNavigate } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import filterLogo from '../../assets/Images/filterLogo.svg';
import filterLogoWhite from '../../assets/Images/filterLogoWhite.svg';

import FilterSection from './FilterSection';
import { Footer } from '../Footer/Footer';
import toast from 'react-hot-toast';
import CloseIconForCC from '../../assets/Images/CloseIconForCC.svg';

// Import your SVG icons

import mode from '../../assets/Images/mode.svg';
import session from '../../assets/Images/session.svg';
import dateImg from '../../assets/Images/dateImg.svg';
import locationImg from '../../assets/Images/locationImg.svg';
import certification from '../../assets/Images/certification.svg';

export const SearchInternship = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [
        { role, username, uid },
        { showMenuModal, showNotifyAvailable, showNotifyModal },
        { appConfig },
    ] = useAppSelector(({ signin, modal, skill, notification, appConfig }) => [
        signin['signinValue'],
        modal,
        appConfig['configData'],
    ]);

    const internshipState = useAppSelector(
        (state) => state.internshipSearch.Value
    );
    const { input: internships } = internshipState;

    console.log(internshipState);

    const [showFilterOptions, setShowFilterOptions] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [compareInternships, setCompareInternships] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [showCompareModal, setShowCompareModal] = useState(false);

    useEffect(() => {
        const storedSearchTerm = localStorage.getItem('searchTerm');
        if (storedSearchTerm) {
            setSearchTerm(storedSearchTerm.toLowerCase());
        }
    }, [internshipState]);

    const [activeFilters, setActiveFilters] = useState({
        mode: [],
        days: [],
        certificate: [],
    });

    const handleFilterChange = (type, value) => {
        setActiveFilters((prev) => {
            const isValueSelected = prev[type].includes(value);
            if (isValueSelected) {
                return {
                    ...prev,
                    [type]: prev[type].filter((item) => item !== value),
                };
            } else {
                return {
                    ...prev,
                    [type]: [...prev[type], value],
                };
            }
        });
    };

    const handleCompareChange = (internship, isChecked) => {
        if (isChecked) {
            if (compareInternships.length >= 3) {
                toast.error('Compare Maximum 3 Internships');
                return;
            }
            setCompareInternships([...compareInternships, internship]);
            setSelectedIds([...selectedIds, internship.id]);
            setShowCompareModal(true);
        } else {
            setCompareInternships(
                compareInternships.filter((item) => item.id !== internship.id)
            );
            setSelectedIds(selectedIds.filter((id) => id !== internship.id));
            if (compareInternships.length <= 1) {
                setShowCompareModal(false);
            }
        }
    };

    const clearAllFilters = () => {
        setActiveFilters({
            mode: [],
            days: [],
            certificate: [],
        });
    };

    const handleClearAll = () => {
        setCompareInternships([]);
        setSelectedIds([]);
        setShowCompareModal(false);
    };

    const handleRemoveFromCompare = (internshipId) => {
        setCompareInternships(
            compareInternships.filter((item) => item.id !== internshipId)
        );
        setSelectedIds(selectedIds.filter((id) => id !== internshipId));
        if (compareInternships.length <= 1) {
            setShowCompareModal(false);
        }
    };

    // const handleCompare = () => {
    //     if (compareInternships.length >= 2) {
    //         // We'll implement navigation to comparison view later
    //         console.log('Opening full comparison view');
    //     } else {
    //         toast.error('Select Minimum 2 Internships');
    //     }
    // };
    // In SearchInternship.js, update handleCompare function
    const handleCompare = () => {
        if (compareInternships.length >= 2) {
            navigate('/comparing-internships', {
                state: {
                    internshipIds: compareInternships.map((i) => i.id),
                    internshipData: compareInternships,
                },
            });
        } else {
            toast.error('Select Minimum 2 Internships');
        }
    };

    // const getFilteredInternships = () => {
    //     return internships
    //         .filter((internship) => {
    //             if (!searchTerm) return true;
    //             const matchesTitle = internship.title
    //                 .toLowerCase()
    //                 .includes(searchTerm);
    //             const matchesTags = internship.tags?.some((tag) =>
    //                 tag.toLowerCase().includes(searchTerm)
    //             );
    //             const matchesDescription = internship.description
    //                 ?.toLowerCase()
    //                 .includes(searchTerm);
    //             return matchesTitle || matchesTags || matchesDescription;
    //         })
    //         .filter((internship) => {
    //             if (activeFilters.mode.length === 0) return true;
    //             if (activeFilters.mode.includes('Both')) {
    //                 return internship.mode === 'Online, Offline';
    //             }
    //             return activeFilters.mode.includes(internship.mode);
    //         })
    //         .filter((internship) => {
    //             if (activeFilters.days.length === 0) return true;
    //             return activeFilters.days.some((selectedDay) =>
    //                 internship.days.includes(selectedDay)
    //             );
    //         })
    //         .filter((internship) => {
    //             if (activeFilters.certificate.length === 0) return true;
    //             const certStatus = internship.certificate ? 'Yes' : 'No';
    //             return activeFilters.certificate.includes(certStatus);
    //         });
    // };

    const getFilteredInternships = () => {
        return internships
            .filter((internship) => {
                // Search term filter
                if (!searchTerm) return true;
                const matchesTitle = internship.title
                    .toLowerCase()
                    .includes(searchTerm);
                const matchesTags = internship.tags?.some((tag) =>
                    tag.toLowerCase().includes(searchTerm)
                );
                const matchesDescription = internship.description
                    ?.toLowerCase()
                    .includes(searchTerm);
                return matchesTitle || matchesTags || matchesDescription;
            })
            .filter((internship) => {
                // Mode filter
                if (activeFilters.mode.length === 0) return true;
                if (activeFilters.mode.includes('Both')) {
                    return internship.mode === 'Online, Offline';
                }
                return activeFilters.mode.includes(internship.mode);
            })
            .filter((internship) => {
                // Days filter
                if (activeFilters.days.length === 0) return true;
                return activeFilters.days.some((selectedDay) =>
                    internship.days.includes(selectedDay)
                );
            })
            .filter((internship) => {
                // Certificate filter
                if (activeFilters.certificate.length === 0) return true;
                const certStatus = internship.certificate ? 'Yes' : 'No';
                return activeFilters.certificate.includes(certStatus);
            })
            .filter(
                (internship) =>
                    // Active and deadline filter
                    internship.is_active &&
                    new Date(internship.application_deadline) > new Date()
            )
            .sort((a, b) => {
                // Duration comparison
                const getDaysFromDuration = (duration, unit) => {
                    switch (unit.toLowerCase()) {
                        case 'years':
                            return duration * 365;
                        case 'months':
                            return duration * 30;
                        case 'weeks':
                            return duration * 7;
                        case 'days':
                            return duration;
                        default:
                            return 0;
                    }
                };

                const daysA = getDaysFromDuration(a.duration, a.duration_unit);
                const daysB = getDaysFromDuration(b.duration, b.duration_unit);

                if (daysA !== daysB) {
                    return daysA - daysB;
                }

                // Creation date comparison
                const dateA = new Date(a.createdAt);
                const dateB = new Date(b.createdAt);

                if (dateB - dateA !== 0) {
                    return dateB - dateA;
                }

                // Start date comparison
                if (a.start_date && b.start_date) {
                    return new Date(a.start_date) - new Date(b.start_date);
                }

                return 0;
            });
    };

    const filteredInternships = getFilteredInternships();

    if (role !== 'ST') {
        return <div>''</div>;
    }

    const hasActiveFilters = () => {
        return Object.values(activeFilters).some((arr) => arr.length > 0);
    };

    return (
        <div className="bg-[#F6F7F8] h-auto">
            <div className="mt-8 w-11/12 mx-auto mb-10 sm:flex gap-12">
                <div>
                    {/* <button
                        onClick={() => setShowFilterOptions(!showFilterOptions)}
                        className="flex items-center gap-2 bg-blue-500 text-white rounded-full px-6 py-2"
                    >
                        <img
                            src={filterLogo}
                            alt="filter"
                            className="w-4 h-4"
                        />
                        Filter
                    </button> */}
                    <button
                        onClick={() => setShowFilterOptions(!showFilterOptions)}
                        className={`flex items-center gap-2 rounded-full px-6 py-2 ${
                            showFilterOptions
                                ? 'bg-blue-500 text-white'
                                : 'bg-white text-black'
                        }`}
                    >
                        <img
                            src={
                                showFilterOptions ? filterLogoWhite : filterLogo
                            }
                            alt="filter"
                            className="w-4 h-4"
                        />
                        Filter
                    </button>
                    {hasActiveFilters() && (
                        <button
                            onClick={clearAllFilters}
                            className="text-[#FF4747] text-sm mt-2 ml-2 "
                        >
                            Clear All
                        </button>
                    )}

                    {showFilterOptions && (
                        <FilterSection
                            filters={activeFilters}
                            handleFilterChange={handleFilterChange}
                        />
                    )}
                </div>

                {/* <div className="mt-6 space-y-6 mb-96"> */}
                <div
                    className={`mt-6 space-y-6 ${
                        showCompareModal ? 'mb-96' : ''
                    }`}
                >
                    {/* <div className="mb-4">
                        {searchTerm && (
                            <p className="text-sm text-gray-500 mb-2">
                                Showing results for: "{searchTerm}"
                            </p>
                        )}
                        <p className="text-sm text-gray-500">
                            Active Filters:
                            {activeFilters.mode.length > 0 &&
                                ` Mode: ${activeFilters.mode.join(', ')}`}
                            {activeFilters.days.length > 0 &&
                                ` Days: ${activeFilters.days.join(', ')}`}
                            {activeFilters.certificate.length > 0 &&
                                ` Certificate: ${activeFilters.certificate.join(
                                    ', '
                                )}`}
                        </p>
                    </div> */}

                    {filteredInternships.length > 0 ? (
                        filteredInternships.map((internship) => (
                            <SearchInternshipCard
                                key={internship.id}
                                internship={internship}
                                isSelected={selectedIds.includes(internship.id)}
                                onCompare={handleCompareChange}
                            />
                        ))
                    ) : (
                        <NoInternshipFound />
                    )}
                </div>
            </div>

            {showCompareModal && compareInternships.length > 0 && (
                <CompareInternshipModal
                    internshipInfo={compareInternships}
                    onClose={handleRemoveFromCompare}
                    onClearAll={handleClearAll}
                    onCompare={handleCompare}
                />
            )}
        </div>
    );
};

const SearchInternshipCard = ({ internship, isSelected, onCompare }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/internship-info/${internship.id}`);
    };

    const handleCompare = (e) => {
        e.stopPropagation();
        onCompare(internship, e.target.checked);
    };

    return (
        <div className="bg-white p-8 rounded-lg  cursor-pointer w-full max-w-[1080px] mt-4 relative ">
            {/* Compare Checkbox */}
            <div
                className="absolute top-4 right-4 flex items-center gap-2 z-10   "
                onClick={(e) => e.stopPropagation()}
            >
                <input
                    type="checkbox"
                    checked={isSelected}
                    onChange={handleCompare}
                    className="w-[21px] h-[21px] border border-[#D9D9D9] rounded-[2px] cursor-pointer"
                />
                <label className="text-[12px] font-[500] text-[#2975FD]">
                    Compare
                </label>
            </div>

            {/* Main Content */}
            <div onClick={handleClick}>
                <div className="flex flex-col sm:flex-row items-start gap-8">
                    {/* Image */}
                    <div className="w-full sm:w-60 flex-shrink-0">
                        <img
                            src={internship.thumbnail}
                            alt={`${internship.title} Thumbnail`}
                            className="w-full h-32 object-contain rounded-md   mt-7  sm:mt-0"
                        />
                    </div>

                    {/* Content */}
                    <div className="flex-grow w-full">
                        <h2 className="text-xl font-semibold text-gray-900 mb-1">
                            {internship.title}
                        </h2>
                        <p className="text-gray-600 mb-4">
                            {internship.company}
                        </p>

                        {/* Info Items Container */}
                        <div className="flex flex-col sm:flex-row flex-wrap items-start sm:items-center gap-4 sm:gap-10">
                            <InfoItem
                                icon={locationImg}
                                text={internship.location}
                            />
                            <InfoItem
                                icon={dateImg}
                                text={`${internship.duration} ${internship.duration_unit}`}
                            />
                            <InfoItem
                                icon={certification}
                                text={
                                    internship.certificate
                                        ? 'Certification Provided'
                                        : 'No Certification'
                                }
                            />
                            <InfoItem
                                icon={locationImg}
                                text={internship.location}
                            />
                            <InfoItem icon={mode} text={internship.mode} />

                            {/* Working Days */}
                            {internship.days && internship.days.length > 0 && (
                                <DaysDisplay days={internship.days} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// DaysDisplay Component
const DaysDisplay = ({ days }) => (
    <div className="flex items-center gap-2  sm:ml-2 ">
        <img src={session} alt="Time Icon" className="w-5 h-5" />
        <div className="flex gap-2">
            {days.map((day, index) => (
                <span
                    key={index}
                    className="sm:text-[14px]  text-[10px]  text-black "
                >
                    {day}
                    {index !== days.length - 1 ? ', ' : ''}
                </span>
            ))}
        </div>
    </div>
);

// Helper component for info items
const InfoItem = ({ icon, text }) => (
    <div className="flex items-center gap-2">
        <div className="bg-slate-400 w-[1px] hidden sm:block" />
        <div className="flex items-center gap-2">
            <span>
                <img src={icon} alt="icon" className="w-4 h-4" />
            </span>
            <p className="text-black whitespace-nowrap">{text}</p>
        </div>
    </div>
);

const CompareInternshipModal = ({
    internshipInfo,
    onClose,
    onClearAll,
    onCompare,
}) => {
    return (
        <div className="flex flex-col w-full">
            <div className="h-auto w-full flex items-center justify-center bg-[#000000] bg-opacity-[0.86] fixed bottom-0 left-0 overflow-hidden z-[50]">
                <div className="flex flex-col space-y-4 p-4 w-full max-w-4xl">
                    {/* Header Section */}
                    <div className="flex justify-between items-center gap-4 w-full bg-black p-2 rounded-md">
                        <h1 className="text-[14px] sm:text-[16px] md:text-[18px] font-[500] text-white">
                            Compare ({internshipInfo.length})
                        </h1>
                        <div className="flex items-center gap-4">
                            <p
                                className="text-white text-[14px] sm:text-[16px] md:text-[18px] font-[400] cursor-pointer"
                                onClick={onClearAll}
                            >
                                Clear
                            </p>
                            <button
                                className="bg-[#085BEB] p-3 text-white text-[14px] sm:text-[16px] md:text-[18px] font-[400] rounded-md shadow-md"
                                onClick={onCompare}
                            >
                                Compare
                            </button>
                        </div>
                    </div>

                    {/* Internship Cards Section */}
                    <div className="flex flex-wrap gap-4 justify-center overflow-y-auto max-h-[300px]">
                        {internshipInfo.map((internship, index) => (
                            <div
                                className="flex justify-center w-full sm:w-[270px]"
                                key={index}
                            >
                                <div className="flex flex-col gap-2 px-4 py-4 bg-white relative w-full sm:w-[270px] rounded-md shadow-md">
                                    <img
                                        src={CloseIconForCC}
                                        alt="CloseIcon"
                                        className="absolute top-2 right-2 cursor-pointer w-[20px] sm:w-[22px] h-[20px] sm:h-[22px]"
                                        onClick={() => onClose(internship.id)}
                                    />
                                    <div className="mt-6">
                                        <img
                                            src={internship.thumbnail}
                                            alt={internship.title}
                                            className="w-full h-[100px] sm:h-[120px] object-contain"
                                        />
                                        <h1 className="mt-2 text-lg sm:text-xl font-[500] pl-3 capitalize">
                                            {internship.title}
                                        </h1>
                                        <p className="text-[#999999] text-[12px] sm:text-[14px] font-[400] pl-3">
                                            {internship.company}
                                        </p>
                                        <div className="mt-2 pl-3">
                                            <p className="text-sm text-gray-600">
                                                {internship.internship_type} •{' '}
                                                {internship.duration}{' '}
                                                {internship.duration_unit}
                                            </p>
                                            <p className="text-sm text-gray-600">
                                                {internship.mode}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

const NoInternshipFound = () => (
    <div className="flex flex-col min-h-[450px] items-center justify-center w-full text-gray-500 ml-80">
        No Internship Found
    </div>
);
